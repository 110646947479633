import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import '@/common/templates/dependencies'

import './plugins/bootstrap-vue'
import './plugins/toaster'
import './plugins/mq'

import api from './plugins/axios'
import moment from './plugins/moment'

import Chart from "admin-lte/plugins/chart.js/Chart.min.js";

Vue.config.productionTip = false

Vue.prototype.$api = api;
Vue.prototype.$moment = moment;
Vue.prototype.$chart = Chart;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
