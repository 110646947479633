import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    isLoading: false,
  },
  getters: {
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
      if (user) {
        state.isMenuVisible = true
        Vue.prototype.$api.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${user.access_token}`;
      } else {
        state.isMenuVisible = false
        delete Vue.prototype.$api.defaults.headers.common[
          "Authorization"
        ]
      }
    },
    setLoading(state, loading) {
      state.isLoading = loading;
    },
  },
  actions: {
  },
  modules: {
  }
})
