<template>
  <div class="content-header">
    <div class="row">
      <Grid cols="12 6">
        <h1>{{ title }}</h1>
        <small class="text-muted">{{ subtitle }}</small>
      </Grid>
      <Grid cols="12 6">
        <BreadCrumb :label="title" />
      </Grid>
    </div>
    <hr />
    <div v-if="!hideHistory" class="mb-2">
      <a href="javascript:history.back()"><i class="fas fa-arrow-left mr-2"></i>Voltar</a>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "../layouts/BreadCrumb";
import Grid from "../layouts/Grid";

export default {
  name: "ContentHeader",
  components: { BreadCrumb, Grid },
  props: ["title", "subtitle", "hideHistory"]
};
</script>
