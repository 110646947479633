<template>
  <aside class="control-sidebar control-sidebar-dark">
    <div class="p-3 control-sidebar-content">
      <div class="sidebar-header">
        <h5>{{ title }}</h5>
      </div>
      <hr class="mb-2">
      <slot name="sidebar-content" />
    </div>
  </aside>
</template>

<script>
export default {
  name: "SidebarFilter",
  props: ["title"],
};
</script>

<style>
.control-sidebar-dark label {
    color: #c2c7d0 !important;
}
.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>