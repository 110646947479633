<template>
  <div :class="gridClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Grid",
  props: ["cols"],
  computed: {
    gridClass() {
      const cols = this.cols ? this.cols.split(" ") : [];
      let classes = "";

      if (cols[0]) classes += `col-xs-${cols[0]}`;
      if (cols[1]) classes += ` col-sm-${cols[1]}`;
      if (cols[2]) classes += ` col-md-${cols[2]}`;
      if (cols[3]) classes += ` col-lg-${cols[3]}`;

      return classes;
    },
  },
};
</script>

<style>
</style>