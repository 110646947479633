<template>
  <div>
    <ContentHeader title="Payments Pending" :subtitle="resumo" />
    <b-container fluid>
      <TablePagamento
        :items="pagamentos"
        :fields="fields"
        ref="table"
        @resumoPagamentos="resumo = $event"
        @pagar="pagarLancamentos($event.items, $event.data_pgto)"
        @pagarPagamentos="pagarPagamentos($event.items, $event.data_pgto)"
      >
        <template v-slot:actions="{ item }">
          <b-btn-group size="sm">
            <b-btn variant="primary" @click="$refs.table.toggleDetails(item)">
              <i
                :class="[
                  {
                    'fa fa-caret-down': !item._showDetails,
                    'fa fa-caret-up': item._showDetails,
                  },
                ]"
              ></i>
            </b-btn>
            <b-btn
              variant="warning"
              @click="$router.push(`/lancamento/${item.id}`)"
            >
              <i class="fa fa-pen"></i>
            </b-btn>
            <b-btn variant="success" @click.prevent="pagar(item)">
              <i class="fas fa-dollar-sign"></i> Pagar
            </b-btn>
          </b-btn-group>
        </template>
      </TablePagamento>
    </b-container>
  </div>
</template>

<script>
import ContentHeader from "../../common/templates/ContentHeader";
import TablePagamento from "./TablePagamento";
import { showError, currencyMask } from "@/functions";

export default {
  name: "PagamentoPendente",
  components: { ContentHeader, TablePagamento },
  data() {
    return {
      pagamentos: [],
      resumo: "",
      fields: [
        {
          key: "checkbox",
          label: "Selecionar",
        },
        {
          key: "data_deposito_pagamento",
          label: "Data Pagamento",
          sortable: true,
          class: "text-left",
        },
        {
          key: "descricao",
          label: "Descrição",
          class: "text-left",
          sortable: true,
        },
        {
          key: "valor",
          label: "Valor",
          formatter: (valor) => {
            return currencyMask(valor);
          },
          class: "text-left",
        },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  methods: {
    getPagamentos() {
      this.$store.commit("setLoading", true);
      this.$api
        .get(`${process.env.VUE_APP_LANCAMENTOS_V1}/pagamentos_pendentes`)
        .then((res) => {
          this.$store.commit("setLoading", false);
          this.pagamentos = res.data;
        })
        .catch((e) => {
          this.$store.commit("setLoading", false);
          showError(e);
        });
    },
    pagar(lancamento) {
      const { id } = lancamento;
      this.$api
        .put(`${process.env.VUE_APP_LANCAMENTOS_V1}/${id}`, {
          ...lancamento,
          pago: 1,
        })
        .then(() => {
          this.$refs.table.limparFiltro();
          this.getPagamentos();
        })
        .catch(showError);
    },
    pagarPagamentos(lancamentos, data_pgto) {
      this.$store.commit("setLoading", true);
      let i = 0;
      lancamentos.map((lancamento) => {
        this.$api
          .put(`${process.env.VUE_APP_LANCAMENTOS_V1}/${lancamento.id}`, {
            ...lancamento,
            pago: 1,
            data_deposito_pagamento: data_pgto,
          })
          .then(() => {
            i++;
            if (i == lancamentos.length) {
              this.$toasted.global.defaultSuccess();
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            }
          })
          .catch((e) => {
            showError(e);
            this.$store.commit("setLoading", false);
          });
      });
    },
  },
  mounted() {
    this.getPagamentos();
  },
};
</script>

<style>
</style>