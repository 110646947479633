import Vue from "vue";
import store from "./store";

export function showError(e) {
  if (e && e.response && e.response.data.errors) {
    Object.keys(e.response.data.errors).map(err => {
      Vue.toasted.global.defaultError({ msg: e.response.data.errors[err] });
    })
  } else if (e.response.data.error) {
    Vue.toasted.global.defaultError({ msg: e.response.data.error });
  } else {
    Vue.toasted.global.defaultError();
  }
}

export function FirstLetterUpper([first = '', ...rest]) {
  return [first.toUpperCase(), ...rest].join('');
}

export async function grupos() {
  return await Vue.prototype.$api
    .get(`${process.env.VUE_APP_CATEGORIAS_V1}/grupos`)
    .then((res) => {
      return res.data
    })
}

export async function subgrupos(grupo) {
  store.commit("setLoading", true)
  return await Vue.prototype.$api
    .get(`${process.env.VUE_APP_CATEGORIAS_V1}/${grupo}/subgrupos`)
    .then((res) => {
      store.commit("setLoading", false)
      return res.data
    })
}

export async function formas(tipo) {
  return await Vue.prototype.$api
    .get(`${process.env.VUE_APP_MEIOS_V1}/${tipo}/formas`)
    .then((res) => {
      return res.data
    })
}

export function currencyMask(value) {
  return Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(value);
}