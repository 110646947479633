<template>
  <b-container fluid class="p-4">
    <Grid>
      <h3><strong>Erro 404</strong></h3>
      <hr />
      <h1><strong>Ops,</strong><br>Página não encontrada!</h1>
      <p><a href="/home">Voltar ao Home.</a></p>
    </Grid>
  </b-container>
</template>

<script>
import Grid from "../common/layouts/Grid";

export default {
  name: "NotFound",
  components: { Grid },
};
</script>

<style>
</style>