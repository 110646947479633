<template>
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"
          ><i class="fas fa-bars"></i
        ></a>
      </li>
      <li class="nav-item d-none d-sm-inline-block">
        <a href="/home" class="nav-link">Home</a>
      </li>
    </ul>
    <ul class="navbar-nav ml-auto">
      <li class="nav-item dropdown user user-menu">
        <a href="#" class="nav-link dropdown-toggle" data-toggle="dropdown">
          <Gravatar
            :email="user.email"
            class="user-image img-circle elevation-2"
            alt="User"
          />
          <span class="hidden-xs d-none d-sm-inline-block">{{ user.name }}</span>
        </a>
        <ul class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <li class="user-header">
            <Gravatar
              :email="user.email"
              class="img-circle elevation-2"
              alt="User"
            />
            <p>
              {{ user.name }}
              <small>{{ user.email }}</small>
            </p>
          </li>
          <!-- <li class="user-body">
            <div class="text-center">
              <a href="#"><i class="fas fa-user"></i>Profile</a>
            </div>
          </li> -->
          <li class="user-footer d-flex">
            <div class="mr-auto">
              <a
                class="btn btn-default"
                role="button"
                @click.prevent="adminPanel"
                ><i class="fas fa-cogs"></i>Adminstration</a
              >
            </div>
            <div>
              <a 
              class="btn btn-default" 
              role="button" 
              @click.prevent="logout"
                ><i class="fas fa-sign-out-alt"></i>Logout</a
              >
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapState } from "vuex";
import Gravatar from "vue-gravatar";

export default {
  name: "Header",
  computed: mapState(["user"]),
  components: { Gravatar },
  methods: {
    adminPanel() {
      return this.$router.push("/admin");
    },
    logout() {
      document.cookie = `userKey=; Max-Age=0; path=/; domain=${location.hostname}`;
      this.$store.commit("setUser", null);
      this.$router.push({ name: "auth" }).catch(() => {});
    },
  },
};
</script>

<style>
.user-body i,
.user-footer i {
  margin-right: 6px;
}

a.btn.btn-default {
  background-color: #f8f9fa;
  border-color: #ddd;
  color: #444;
}
</style>