<template>
  <div>
    <canvas id="bars" :height="height" :width="width" />
  </div>
</template>

<script>
import { currencyMask } from "@/functions";

export default {
  name: "Bars",
  props: ["height", "width", "items", "type"],
  data() {
    return {
      config: null,
    };
  },
  methods: {
    randomColor() {
      var r = Math.floor(Math.random() * 255);
      var g = Math.floor(Math.random() * 255);
      var b = Math.floor(Math.random() * 255);
      return `rgb(${r}, ${g}, ${b})`;
    },
  },
  watch: {
    items() {
      let data = {
        labels: [],
        values: [],
        colors: [],
      };
      this.items.map((i) => {
        data.labels.push(i.label);
        data.values.push(i.value);
        data.colors.push(this.randomColor());
      });

      this.config = {
        type: this.type || "bar",
        data: {
          labels: data.labels,
          datasets: [
            {
              axis: "y",
              fill: false,
              data: data.values,
              backgroundColor: data.colors,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          label: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltips: {
            callbacks: {
              label(value) {
                return currencyMask(value.xLabel);
              },
            },
          },
          scales: {
            yAxes: [
              {
                stacked: true,
                ticks: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  display: false,
                },
              },
            ],
          },
        },
      };

      const bars = document.getElementById("bars");
      new this.$chart(bars, this.config);
    },
  },
};
</script>

<style>
</style>