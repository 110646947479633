<template>
  <div class="login-wrapper">
    <b-col md="8" sm="5" class="px-0 d-none d-sm-block">
      <div class="wallpaper"></div>
    </b-col>
    <b-col md="4">
        <div class="row justify-content-center my-5">
          <b-col md="8" sm="5" xs="12">
            <h1 class="mb-3 text-center">
              <i class="fas fa-dollar-sign mr-2"></i>MyFinances
            </h1>
            <b-form>
              <FormGroup label_for="usuario-email">
                <b-input-group>
                  <b-form-input
                    id="usuario-email"
                    type="email"
                    v-model="usuario.email"
                    required
                    placeholder="Email"
                  />
                  <b-input-group-append>
                    <span class="input-group-text"
                      ><i class="fas fa-envelope"></i
                    ></span>
                  </b-input-group-append>
                </b-input-group>
              </FormGroup>
              <FormGroup label_for="usuario-password">
                <b-input-group>
                  <b-form-input
                    id="usuario-password"
                    :type="typePassword"
                    v-model="usuario.password"
                    required
                    placeholder="Senha"
                  />
                  <b-input-group-append
                    style="cursor: pointer"
                    @click="visiblePassword"
                  >
                    <span class="input-group-text"
                      ><i :class="`fa fa-${iconPassword}`"></i
                    ></span>
                  </b-input-group-append>
                </b-input-group>
              </FormGroup>
              <b-btn
                variant="primary"
                size="lg"
                class="btn-login"
                block
                @click="login"
                :disabled="loggingIn"
              >
                <i v-if="loggingIn" class="fa fa-spinner fa-spin"></i>
                <span v-if="!loggingIn">Entrar</span>
              </b-btn>
            </b-form>
          </b-col>
          <div class="footer-login">
            <small class="bottom text-muted"
              >Developed by Max Fernandes de Souza. All rights reserved.</small
            >
          </div>
        </div>
    </b-col>
  </div>
</template>

<script>
import { showError } from "@/functions";
import FormGroup from "../../common/form/FormGroup";

export default {
  name: "Auth",
  components: { FormGroup },
  data() {
    return {
      isLogin: false,
      usuario: {},
      loggingIn: false,
      showPassword: false,
      typePassword: "password",
      iconPassword: "eye",
    };
  },
  methods: {
    visiblePassword() {
      this.showPassword = !this.showPassword;
      this.typePassword = !this.showPassword ? "password" : "text";
      this.iconPassword = !this.showPassword ? "eye" : "eye-slash";
    },
    async login() {
      (this.loggingIn = true),
        await this.$api
          .post(`${process.env.VUE_APP_BASE_API}/login`, this.usuario)
          .then((res) => {
            document.cookie = `userKey=${JSON.stringify(res.data)}`;
            this.$store.commit("setUser", res.data);
            this.$router.push("/home");
          })
          .catch((e) => {
            showError(e), (this.loggingIn = false);
          });
    },
    // async signin() {
    //   this.$store.commit("LOGIN_PENDING");

    //   await axios
    //     .post(`${process.env.VUE_APP_BASE_API_URL}/login`, this.usuario)
    //     .then((res) => {
    //       this.$store.commit("setUser", res.data);
    //       document.cookie = `token=${res.data.token}`;
    //       this.$store.commit("SET_TOKEN", res.data.token);
    //       this.$store.commit("LOGIN_COMPLETE");
    //     })
    //     .catch(() => {
    //       showError;
    //       this.$store.commit("SET_INVALID_LOGIN_CREDENTIALS", true);
    //       this.$store.commit("LOGIN_COMPLETE");
    //     });
    // },
  },
};
</script>

<style>
.login-wrapper {
  background-color: #f4f6f9;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  height: 100vh;
}

.footer-login {
  position: absolute;
  bottom: 0;
  right: 30px;
  left: 30px;
  border-top: 1px solid #c0c0c0;
  padding: 2% 0;
}

.login-wrapper .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
  border-right: none !important;
}

.input-group > .input-group-append > .input-group-text {
  background: #fff;
  border-left: none !important;
}

.wallpaper {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("@/assets/wallpaper_login.jpg") no-repeat;
  background-size: cover;
}
</style>