<template>
    <div class="card-body">
      <slot />
  </div>
</template>

<script>
export default {
  name: "TabsContent"
}
</script>

<style>

</style>