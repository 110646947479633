import Vue from 'vue'
import VueRouter from 'vue-router'

import Auth from '@/components/auth/Auth'
import Home from "@/components/home/Home"
/** Admin */
import AdminPanel from '@/components/admin/AdminPanel'
/** Releases */
import Lancamento from '@/components/lancamento/Lancamento'
import LancamentoDetalhes from '@/components/lancamento/LancamentoDetalhes'
import LancamentoMes from '@/components/lancamento/LancamentoMes'
import LancamentoAnual from '@/components/lancamento/LancamentoAnual'
import LancamentoFixoDetalhes from '@/components/lancamento/LancamentoFixoDetalhes'
import LancamentoMesResumo from '@/components/lancamento/LancamentoMesResumo'
/** Payments */
import PagamentoPendente from '@/components/pagamento/PagamentoPendente'

import NotFound from '@/components/NotFound'

Vue.use(VueRouter)

const routes = [
  { path: '/', component: Home },
  {
    path: '/auth',
    name: 'auth',
    component: Auth
  },
  {
    path: '/home',
    name: 'home',
    component: Home
  },
  {
    path: '/admin',
    name: 'adminPanel',
    component: AdminPanel
  },
  {
    path: '/lancamentos',
    component: Lancamento,
    children: [
      { path: '/', component: Home },
      { path: '/lancamento', name: 'lancamento.novo', component: LancamentoDetalhes },
      { path: '/lancamento/:id', name: 'lancamento.editar', component: LancamentoDetalhes },
      { path: '/lancamento/:mes/mes/:ano/ano/detalhes', name: 'lancamento.mes.detalhes', component: LancamentoMes },
      { path: '/lancamento/:mes/mes/:ano/ano/resumo', name: 'lancamento.mes.resumo', component: LancamentoMesResumo },
      { path: '/lancamento_anual', name: 'lancamento.anual', component: LancamentoAnual },
      { path: '/lancamento_fixo', name: 'lancamento.fixo', component: LancamentoFixoDetalhes },
    ]
  },
  { path: '/pagamento_pendente', name: 'pagamento.pendente', component: PagamentoPendente },
  { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
