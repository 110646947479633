<template>
    <canvas :id="id" :height="height" :width="width" />
</template>

<script>
import { currencyMask } from "@/functions";

export default {
  name: "Pie",
  props: ["id", "height", "width", "items", "type", "tooltipLabel"],
  data() {
    return {
      config: null,
    };
  },
  methods: {
    randomColor() {
      var r = Math.floor(Math.random() * 255);
      var g = Math.floor(Math.random() * 255);
      var b = Math.floor(Math.random() * 255);
      return "rgb(" + r + "," + g + "," + b + ")";
    },
  },
  watch: {
    $route(){
      window.location.reload();
    },
    items() {
      let data = {
        labels: [],
        values: [],
        colors: [],
      };
      this.items.map((i) => {
        data.labels.push(i.label);
        data.values.push(i.value);
        data.colors.push(i.color || this.randomColor());
      });

      this.config = {
        type: this.type || "pie",
        data: {
          labels: data.labels,
          tooltipLabel: this.tooltipLabel || "numeric",
          datasets: [
            {
              data: data.values,
              backgroundColor: data.colors,
              hoverOffset: 4,
            },
          ],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: "bottom",
          },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                let text = data.labels[tooltipItem.index];
                let value =
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];

                let label = "";
                switch (data.tooltipLabel) {
                  case "currency":
                    label = `${text}: ${currencyMask(value)}`;
                    break;
                  case "percentual":
                    label = `${text}: ${value}%`;
                    break;
                  default:
                    label = `${text}: ${value}`;
                    break;
                }
                return label;
              },
            },
          },
        },
      };

      const pie = document.getElementById(this.id);
      new this.$chart(pie, this.config);
    },
  },
};
</script>

<style>
#pie {
  display: block;
  height: 100px;
  width: 422px;
}
</style>