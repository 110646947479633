<template>
  <div>
    <ContentHeader
      title="Fixed Release"
      subtitle="Fixed releases in the year"
    />
    <b-container fluid>
      <b-form class="pb-4">
        <Row>
          <Grid cols="12 2">
            <FormGroup label="Ano" label_for="lancamento-ano">
              <b-form-input
                id="lancamento-ano"
                type="number"
                v-model="ano"
                required
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 3">
            <FormGroup
              :label="`Dia de ${
                tipo == 'SAÍDA' ? 'pagamento' : 'recebimento'
              } (+/-)`"
              label_for="lancamento-dia-deposito-pagamento"
            >
              <b-form-select
                id="lancamento-dia-deposito-pagamento"
                v-model="dia_deposito_pagamento"
              >
                <template #first>
                  <option value="">--- none ---</option>
                  <option v-for="n in 30" :key="n">{{ n }}</option>
                </template>
              </b-form-select>
            </FormGroup>
          </Grid>
          <Grid cols="12 3">
            <FormGroup label="Tipo" label_for="lancamento-tipo">
              <b-form-radio-group
                id="lancamento-tipo"
                v-model="tipo"
                :options="['ENTRADA', 'SAÍDA']"
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 4">
            <FormGroup label="Valor" label_for="lancamento-valor">
              <b-form-input
                id="lancamento-valor"
                type="number"
                v-model="lancamento.valor"
                required
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 10" label_for="lancamento-meses" class="grid-meses">
            <FormGroup label="Mês" class="p-1">
              <b-form-checkbox
                v-model="meses"
                class="d-inline mr-4"
                v-for="(item, key) in mes"
                :key="key"
                :value="item.value"
                >{{ item.text }}</b-form-checkbox
              >
            </FormGroup>
          </Grid>
          <Grid cols="12 2" class="d-flex">
            <FormGroup
              label="Todos os meses"
              label_for="lancamento-todos-meses"
            >
              <b-form-checkbox
                id="lancamento-todos-meses"
                v-model="todosMeses"
                size="lg"
                switch
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 6">
            <FormGroup label="Forma" label_for="lancamento-forma">
              <b-form-select
                id="lancamento-forma"
                v-model="forma"
                :options="formas || []"
                @change="onCategoria('meio_id')"
              >
                <template #first>
                  <option value="">--- none ---</option>
                </template>
              </b-form-select>
            </FormGroup>
          </Grid>
          <Grid cols="12 6">
            <FormGroup label="TAG" label_for="lancamento-tag">
              <b-form-input
                id="lancamento-tag"
                type="text"
                v-model="lancamento.tag"
                required
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 6">
            <FormGroup label="Grupo" label_for="lancamento-grupo">
              <b-form-select
                id="lancamento-grupo"
                v-model="grupo"
                @change="onCategoria('grupo')"
                :options="grupos || []"
                :disabled="tipo === 'ENTRADA'"
              >
                <template #first>
                  <option value="">--- none ---</option>
                </template>
              </b-form-select>
            </FormGroup>
          </Grid>
          <Grid cols="12 6">
            <FormGroup label="Subgrupos" label_for="lancamento-subgrupo">
              <b-form-select
                id="lancamento-subgrupo"
                v-model="subgrupo"
                @change="onCategoria('categoria_id')"
                :options="subgrupos || []"
              >
                <template #first>
                  <option value="">--- none ---</option>
                </template>
              </b-form-select>
            </FormGroup>
          </Grid>
          <Grid cols="12 12">
            <FormGroup label="Descrição" label_for="lancamento-descricao">
              <b-form-textarea
                id="lancamento-descricao"
                v-model="lancamento.descricao"
                placeholder="Descreva os dados do lançamento..."
                rows="2"
                max-rows="4"
              />
            </FormGroup>
          </Grid>
        </Row>
        <hr />
        <div class="d-flex justify-content-end">
          <b-btn
            variant="success"
            block
            :class="`px-0 ${this.$mq !== 'sm' ? 'w-25' : ''}`"
            @click="save"
          >
            <i class="fa fa-check"></i>
            Salvar
          </b-btn>
        </div>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import ContentHeader from "../../common/templates/ContentHeader";
import Row from "../../common/layouts/Row";
import Grid from "../../common/layouts/Grid";
import FormGroup from "../../common/form/FormGroup";

import {
  formas,
  grupos,
  subgrupos,
  meses,
} from "../../common/constants/options";
import { showError } from "@/functions";

export default {
  name: "LancamentoFixoDetalhes",
  components: { ContentHeader, Row, Grid, FormGroup },
  data() {
    return {
      tipo: "SAÍDA",
      forma: "",
      grupo: "",
      subgrupo: "",
      ano: "",
      dia_deposito_pagamento: "",
      formas: [],
      grupos: [],
      subgrupos: [],
      lancamento: {
        pago: 0,
        despesa_fixa: 1,
      },
      lancamentos: [],
      mes: meses(),
      meses: [],
    };
  },
  methods: {
    save() {
      this.meses.map((mes) => {
        var data_lancamento = this.$moment(
          `01/${mes}/${this.ano}`,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");

        var data_deposito_pagamento = this.$moment(
          `${
            mes == 2 && this.dia_deposito_pagamento > 28
              ? 28
              : this.dia_deposito_pagamento
          }/${mes}/${this.ano}`,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD");

        var descricao = `${this.lancamento.descricao} ${this.$moment(
          `01/${mes}/${this.ano}`,
          "DD/MM/YYYY"
        )
          .format("MMM/YYYY")
          .toUpperCase()}`;

        this.lancamentos.push({
          ...this.lancamento,
          data_lancamento,
          data_deposito_pagamento,
          descricao,
        });
      });

      var mes = 1;
      this.$store.commit("setLoading", true);
      this.lancamentos.map((lancamento) => {
        this.$api
          .post(`${process.env.VUE_APP_LANCAMENTOS_V1}`, lancamento)
          .then(() => {
            if (mes == this.meses.length) {
              this.$toasted.global.defaultSuccess();
              this.$store.commit("setLoading", false);
              this.$router.push("/home").catch(() => {});
            }
            mes++;
          })
          .catch((e) => {
            showError(e);
            this.$store.commit("setLoading", false);
          });
      });
    },
    onCategoria(col) {
      switch (col) {
        case "meio_id":
          if (!this.parcelamento) {
            this.qtd_parcelas = "";
          }
          this.lancamento = { ...this.lancamento, [col]: this.forma };
          break;
        case "categoria_id":
          this.lancamento = { ...this.lancamento, [col]: this.subgrupo };
          break;
        case "pago":
          this.lancamento = {
            ...this.lancamento,
            [col]: !this.pago ? 0 : 1,
          };
          break;
        case "grupo":
          this.subgrupo = "";
          this.lancamento.categoria_id = "";
          break;
      }
    },
  },
  watch: {
    async tipo(tipo) {
      this.lancamento.meio_id = "";
        this.grupo = "";
      this.formas = await formas(tipo);

      if(tipo === 'ENTRADA'){
        this.grupo = "RECEITA";
      }
    },
    async grupo(grupo) {
      if (grupo) {
        this.subgrupos = await subgrupos(this.grupo);
      } else {
        this.subgrupo = "";
        this.subgrupos = [];
      }
    },
  },
  computed: {
    todosMeses: {
      get() {
        return this.mes ? this.meses.length == this.mes.length : false;
      },
      set(value) {
        var meses = [];

        if (value) {
          this.mes.map((m) => {
            meses.push(m.value);
          });
        }
        this.meses = meses;
      },
    },
  },
  async mounted() {
    this.grupos = await grupos(this.tipo);
    this.formas = await formas(this.tipo);
  },
};
</script>

<style>
.grid-meses label {
  font-weight: normal !important;
}
</style>