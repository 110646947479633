import axios from "axios";
import store from "@/store";
import router from "@/router";

axios.create({ baseURL: process.env.VUE_APP_BASE_API })

axios.interceptors.response.use((response) => {
  return response
}, function (error) {
  if (error.response.status === 401) {
    document.cookie = `userKey=; Max-Age=0; path=/; domain=${location.hostname}`;
    router.push("/auth").catch(() => { })
  }
  return Promise.reject(error);
})

export default axios

// Vue.prototype.$api.interceptors.response.use((response) => {
//   return response
// }, function (error) {
//   let originalRequest = error.config

//   if (error.response.status === 401 && !originalRequest._retry) {
//     originalRequest._retry = true;
//     let user = auth.currentUser;
//     await store.dispatch("setUser", { user: user, refresh: true }).then(() => {
//       const token = store.getters.getSessionToken;
//       Vue.prototype.$api.defaults.headers.common['Authorization'] = 'Bearer ' + token;
//       return Vue.prototype.$api.request(originalRequest);
//     });
//   }
//   return Promise.reject(error);
// });
