import Vue from 'vue'
import moment from 'moment'

import { FirstLetterUpper } from '@/functions';


moment.locale('pt-br')

Vue.filter('dataMesAno', value => {
  const data = moment(value, "MM/YYYY").format('MMMM/YYYY');
  return FirstLetterUpper(data);
})

Vue.filter('diaMesAno', value => {
  const data = moment(value, "YYYY-MM-DD").format('DD/MM/YYYY');
  return FirstLetterUpper(data);
})

export default moment