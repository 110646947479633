<template>
  <li class="nav-item">
    <a
      @click="$emit('showTab', target)"
      :class="`nav-link ${active ? 'active' : ''}`"
      data-toggle="pill"
      role="tab"
      >{{ title }}</a
    >
    <slot />
  </li>
</template>

<script>
export default {
  name: "TabHeader",
  props: ["title", "active","target"],
};
</script>

<style>
</style>