<template>
  <div class="admin-middle">
    <b-form>
      <b-row>
        <Grid cols="12 3">
          <FormGroup label="Tipos">
            <b-form-radio-group
              v-model="meio.tipo"
              :options="tipos"
              :disabled="mode === 'remove' ? true : false"
            ></b-form-radio-group>
          </FormGroup>
        </Grid>
        <Grid cols="12 6">
          <FormGroup label="Forma" label_for="meio-forma">
            <b-form-input
              id="meio-forma"
              type="text"
              v-model="meio.forma"
              required
              placeholder="Informe a Froma..."
              :disabled="mode === 'remove' ? true : false"
            />
          </FormGroup>
        </Grid>
        <Grid cols="12 3" class="mt-4 text-right">
          <b-btn variant="primary" v-if="mode === 'save'" @click="save"
            >Salvar</b-btn
          >
          <b-btn variant="danger" v-if="mode === 'remove'" @click="remove"
            >Excluir</b-btn
          >
          <b-btn class="m-2" @click="reset">Cancelar</b-btn>
        </Grid>
      </b-row>
    </b-form>
    <hr />
    <Table :items="meios" :fields="fields">
      <template v-slot:actions="{ item }">
        <b-btn-group size="sm">
          <b-btn variant="warning" @click="setMeio(item)">
            <i class="fa fa-pen"></i>
          </b-btn>
          <b-btn variant="danger" @click="setMeio(item, 'remove')">
            <i class="fa fa-trash"></i>
          </b-btn>
        </b-btn-group>
      </template>
    </Table>
  </div>
</template>

<script>
import { showError } from "@/functions";
import Table from "../../common/layouts/Table";
import FormGroup from "../../common/form/FormGroup";
import Grid from "../../common/layouts/Grid";

export default {
  name: "AdminMiddle",
  components: { Table, Table, FormGroup, Grid },
  data() {
    return {
      mode: "save",
      meio: { tipo: "SAÍDA" },
      meios: [],
      formas: [],
      fields: [
        { key: "id", label: "#" },
        { key: "tipo", label: "Tipo", sortable: true },
        { key: "forma", label: "Froma", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      tipos: ["ENTRADA", "SAÍDA"],
    };
  },
  methods: {
    getMeios() {
      this.$store.commit("setLoading", true);
      this.$api.get(`${process.env.VUE_APP_MEIOS_V1}`).then((res) => {
        this.meios = res.data;
        this.$store.commit("setLoading", false);
      });
    },
    setMeio(item, mode = "save") {
      this.mode = mode;
      this.tipo = item.tipo;
      this.meio = { ...item };
    },
    reset() {
      this.mode = "save";
      this.meio.tipo = "SAÍDA";
      this.getMeios();
      this.$store.commit("setLoading", false);
    },
    save() {
      const method = this.meio.id ? "put" : "post";
      const baseURL = this.meio.id
        ? `${process.env.VUE_APP_MEIOS_V1}/${this.meio.id}`
        : `${process.env.VUE_APP_MEIOS_V1}`;

      this.$store.commit("setLoading", true);
      this.$api[method](baseURL, this.meio)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.reset();
        })
        .catch(showError);
    },
    remove() {
      this.$store.commit("setLoading", true);
      const id = this.meio.id;
      this.$api
        .delete(`${process.env.VUE_APP_MEIOS_V1}/${id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.reset();
        })
        .catch(showError);
    },
  },
  mounted() {
    this.getMeios();
  },
};
</script>

<style>
</style>