<template>
  <b-container fluid>
    <Row>
      <Grid cols="12 4">
        <FormGroup label_for="filter-input">
          <b-input-group>
            <b-input-group-prepend>
              <span class="input-group-text"><i class="fa fa-search"></i></span>
            </b-input-group-prepend>
            <b-form-input
              id="filter-input"
              v-model="filter"
              placeholder="Search..."
            />
          </b-input-group>
        </FormGroup>
      </Grid>
      <Grid cols="12 4">
        <FormGroup
          label="Forma"
          label_for="filtro-forma"
          label_align_sm="right"
          label_cols_sm="2"
        >
          <b-form-select
            id="filtro-forma"
            value=""
            :options="formas || []"
            @change="onFilterTable('forma', $event)"
          >
            <template #first>
              <option value="">-- none --</option>
            </template>
          </b-form-select>
        </FormGroup>
      </Grid>
      <Grid cols="12 2">
        <FormGroup
          label="Mês"
          label_for="filtro-mes"
          label_align_sm="right"
          label_cols_sm="2"
        >
          <b-input-group>
            <b-form-select
              id="filtro-mes"
              v-model="filtro.mes"
              :options="meses"
              @change="onFilterTable('mes', $event)"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
          </b-input-group>
        </FormGroup>
      </Grid>
      <Grid cols="12 2">
        <FormGroup
          label="Ano"
          label_for="filtro-ano"
          label_align_sm="right"
          label_cols_sm="2"
        >
          <b-input-group>
            <b-form-select
              id="filtro-ano"
              v-model="filtro.ano"
              :options="anos || []"
              @change="onFilterTable('ano', $event)"
            >
              <template #first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
          </b-input-group>
        </FormGroup>
      </Grid>
    </Row>
    <hr />
    <Row class="d-flex justify-content-end pb-4" v-if="itemsChecked.length">
      <Grid cols="12 5" class="my-1">
        <b-form-group
          label="Data do pagamento:"
          class="text-bold"
          label_for="data-pagamento"
          label-cols-sm="12"
          label-cols-md="6"
          label-cols-lg="6"
          label-align-sm="right"
        >
          <b-form-input id="per-page-select" type="date" v-model="data_pgto" />
        </b-form-group>
      </Grid>
      <Grid cols="12 2">
        <b-btn
          variant="success"
          block
          @click="pagarTodos"
          :disabled="data_pgto.length < 10"
          ><i class="fas fa-dollar-sign mr-1"></i>Pagar</b-btn
        >
      </Grid>
    </Row>
    <b-table
      head-variant="dark"
      hover
      striped
      :items="itemsTable"
      :fields="fields"
      :current-page="currentPage"
      :filter-included-fields="filtrarPor"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      aling-item="center"
      stacked="md"
      show-empty
      :empty-text="rowsEmpty"
      :empty-filtered-text="rowsEmpty"
      class="text-center"
      small
      @filtered="onFiltered"
    >
      <template #head(checkbox)>
        <b-form-checkbox v-model="marcarTodos" />
      </template>
      <template #cell(checkbox)="row">
        <b-form-checkbox v-model="itemsChecked" :value="row.item" />
      </template>
      <template #cell(data_deposito_pagamento)="row">
        <b-input
          type="date"
          v-model="row.item.data_deposito_pagamento"
          :readonly="itemsChecked.includes(row.item) ? true : false"
          class="w-75"
        />
      </template>
      <template #cell(actions)="row">
        <slot name="actions" :item="row.item" />
      </template>
      <template #row-details="{ item }">
        <b-card>
          <ul>
            <li v-for="(value, key) in item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
    <b-row class="p-4">
      <Grid cols="12 3" class="my-1">
        <b-form-group
          label="Items:"
          class="text-bold"
          label_for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
        >
          <b-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
          />
        </b-form-group>
      </Grid>
      <Grid cols="12 9" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          align="fill"
        />
      </Grid>
    </b-row>
  </b-container>
</template>

<script>
import Row from "../../common/layouts/Row";
import Grid from "../../common/layouts/Grid";
import { formas, grupos, meses, anos } from "../../common/constants/options";
import FormGroup from "../../common/form/FormGroup";

export default {
  name: "TablePagamento",
  props: ["items", "fields", "filterAll"],
  components: { FormGroup, Row, Grid },
  data() {
    return {
      filter: null,
      filtro: {
        mes: "",
        ano: "",
      },
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalAmount: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      itemsTable: [],
      itemsFiltered: [],
      rowsEmpty: "Nenhum registro encontrado.",
      grupos: [],
      formas: [],
      tipo: "SAÍDA",
      fullFilter: false,
      filterOn: [],
      filtrarPor: [],
      itemsChecked: [],
      data_pgto: "",
      meses: meses(),
      anos: [],
    };
  },
  async mounted() {
    this.grupos = await grupos();
    let todosAnos = await anos();
    todosAnos = todosAnos.filter(
      (ano) => ano <= +this.$moment().format("YYYY")
    );
    todosAnos.push(+this.$moment().format("YYYY"));
    this.anos = todosAnos;
    this.formas = await formas(this.tipo);
  },
  computed: {
    pageOptions() {
      return [10, 15, 20, { value: this.items.length, text: "Mostrar todos" }];
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    marcarTodos: {
      get() {
        return this.itemsTable.length
          ? this.itemsChecked.length == this.itemsTable.length
          : false;
      },
      set(item) {
        var items = [];

        if (item) {
          this.itemsTable.map((i) => {
            items.push(i);
          });
        }
        this.itemsChecked = items;
      },
    },
  },
  watch: {
    items(items) {
      this.itemsTable = this.items;
      this.onFiltered(items);
    },
    itemsTable(items) {
      this.onFiltered(items);
    },
    itemsChecked(items) {
      if (!items.length) this.data_pgto = "";
    },
  },
  methods: {
    pagarTodos() {
      this.$emit("pagarPagamentos", {
        items: this.itemsChecked,
        data_pgto: this.data_pgto,
      });
      this.limparFiltro();
    },
    limparFiltro() {
      this.filter = "";
      this.filtro = { mes: "", ano: "" };
    },
    onFilterTable(key, val) {
      this.filterOn.push({ key, val });

      let filter = this.filterOn.reduce((arr, item) => {
        let data = arr.filter((i) => i.key !== item.key);
        return [...data, item];
      }, []);

      let itemsData = [...this.items];

      filter.filter((f) => {
        let { key, val } = f;
        if (val) {
          switch (key) {
            case "mes":
              itemsData = itemsData.filter(
                ({ data_deposito_pagamento }) =>
                  this.$moment(data_deposito_pagamento).format("MM") == val
              );

              break;
            case "ano":
              itemsData = itemsData.filter(({ data_deposito_pagamento }) =>
                this.$moment(data_deposito_pagamento)
                  .format("YYYY")
                  .includes(val)
              );
              break;
            case "forma":
              // 1 = C.CREDITO; 3 = C.CREDITO (TERCEIROS)
              // 7 = C.CREDITO / PARCELAMENTO; 8 = C.CREDITO (TERCEIROS) / PARCELAMENTO
              if (val === 1) {
                itemsData = itemsData.filter(({ meio_id }) =>
                  [1, 7].includes(meio_id)
                );
              } else if (val === 3) {
                itemsData = itemsData.filter(({ meio_id }) =>
                  [3, 8].includes(meio_id)
                );
              } else {
                itemsData = itemsData.filter(({ meio_id }) => meio_id === val);
              }
              break;
          }
        }
        this.itemsTable = itemsData;
      });
    },

    toggleDetails(row) {
      if (row._showDetails) {
        this.$set(row, "_showDetails", false);
      } else {
        this.items.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });

        this.$nextTick(() => {
          this.$set(row, "_showDetails", true);
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.calculateAmount(filteredItems);
      this.$emit(
        "resumoPagamentos",
        `Payment(s): ${filteredItems.length} | Amount: ${this.totalAmount}`
      );
    },
    calculateAmount(items) {
      this.totalAmount = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(items.reduce((a, b) => a + parseFloat(b.valor), 0));
    },
  },
};
</script>

<style>
.table tr td {
  vertical-align: middle;
}

.input-group-prepend,
.input-group-append {
  background: #fff;
}

.input-group > .input-group-prepend > .input-group-text {
  background: #fff;
  border-right: none !important;
}

.input-group > .form-control:not(:first-child) {
  border-left: none !important;
}

.form-control:focus {
  border-color: #ced4da !important;
  outline: none !important;
  box-shadow: none !important;
}

.input-group-sm > .input-group-append > .btn {
  font-size: 0.8rem !important;
}

.filtrar-por label {
  font-weight: normal !important;
}
</style>