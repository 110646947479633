import Vue from "vue";

export const meses = () => {
  const mes = ["JAN", "FEV", "MAR", "ABR", "MAI", "JUN", "JUL", "AGO", "SET", "OUT", "NOV", "DEZ"];
  return mes.map((m, i) => ({ text: m, value: i + 1 }))
}

export const anos = async () => {
return await Vue.prototype.$api
    .get(`${process.env.VUE_APP_LANCAMENTOS_V1}/anos`)
    .then((res) => res.data.map(ano => ano))
}

export const grupos = async () => {
  return await Vue.prototype.$api
    .get(`${process.env.VUE_APP_CATEGORIAS_V1}/grupos`)
    .then((res) => res.data.map(option => option.grupo))
}

export const subgrupos = async (grupo) => {
  return await Vue.prototype.$api
    .get(`${process.env.VUE_APP_CATEGORIAS_V1}/${grupo}/subgrupos`)
    .then((res) => res.data.map(option => ({ text: option.subgrupo, value: option.id })))
}

export const formas = async (tipo) => {
  return await Vue.prototype.$api
    .get(`${process.env.VUE_APP_MEIOS_V1}/${tipo}/formas`)
    .then((res) => res.data.map(option => ({ text: option.forma, value: option.id })))
}