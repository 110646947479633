<template>
  <div>
    <ContentHeader title="Admin Panel" subtitle="Registered items" />
    <b-container fluid>
      <Tabs>
        <TabsHeader>
          <TabHeader
            v-for="(tab, key) in tabs"
            :key="key"
            :target="tab.id"
            @showTab="tabActive = $event"
            :title="tab.title"
            :active="tab.id === tabActive"
          />
        </TabsHeader>
        <TabsContent>
          <TabContent id="tabCategorias" :active="tabActive === 'tabCategorias'">
            <AdminCategory />
          </TabContent>
          <TabContent id="tabMeios" :active="tabActive === 'tabMeios'">
            <AdminMiddle />
          </TabContent>
        </TabsContent>
      </Tabs>
    </b-container>
  </div>
</template>

<script>
import ContentHeader from "../../common/templates/ContentHeader.vue";
import Tabs from "../../common/tab/Tabs";
import TabsHeader from "../../common/tab/TabsHeader";
import TabHeader from "../../common/tab/TabHeader";
import TabsContent from "../../common/tab/TabsContent";
import TabContent from "../../common/tab/TabContent";

import AdminCategory from "./AdminCategory";
import AdminMiddle from "./AdminMiddle";

export default {
  name: "AdminPanel",
  components: {
    ContentHeader,
    Tabs,
    TabsHeader,
    TabHeader,
    TabsContent,
    TabContent,
    AdminCategory,
    AdminMiddle,
  },
  data() {
    return {
      tabs: [
        { id: "tabCategorias", title: "Categorias" },
        { id: "tabMeios", title: "Meios" },
      ],
      tabActive: "tabCategorias",
    };
  },
  methods: {
  },
  mounted() {
    //this.tabActive = this.tabs[0].id;
  },
};
</script>

<style>
</style>