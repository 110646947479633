<template>
  <aside class="main-sidebar sidebar-dark-primary elevation-4">
    <a href="/home" class="brand-link">
      <i class="fas fa-dollar-sign brand-image"></i>
      <span class="brand-text font-weight-light">MyFinances</span>
    </a>
    <div class="sidebar">
      <Menu />
    </div>
  </aside>
</template>
<script>
import Menu from "./Menu";

export default {
  name: "Sidebar",
  components: { Menu },
};
</script>

<style>
.brand-link i {
  padding-top: 0.5rem;
  font-size: 1.4rem;
}
</style>