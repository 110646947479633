<template>
  <div class="card-header p-0 border-bottom-0">
    <ul class="nav nav-tabs" role="tablist">
      <slot />
    </ul>
  </div>
</template>

<script>
export default {
  name: "TabsHeader"
};
</script>

<style>
</style>