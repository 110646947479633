<template>
  <div class="content-wrapper">
    <b-container fluid>
      <router-view />
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Content"
}
</script>

<style>

</style>