<template>
  <b-form-group
    :id="id"
    :label="label"
    :label-for="label_for"
    class="font-weight-bold"
    :description="description"
    :label-align-sm="label_align_sm"
    :label-cols-sm="label_cols_sm"
  >
    <slot />
  </b-form-group>
</template>

<script>
export default {
  name: "FormGroup",
  props: ["id", "label", "label_for","description","label_align_sm","label_cols_sm"],
};
</script>

<style>
span {
  font-weight: normal;
}
</style>