<template>
  <div>
    <b-container fluid>
      <FormGroup label_for="filter-input">
        <b-input-group>
          <b-input-group-prepend>
            <b-btn
              data-widget="control-sidebar"
              @click="showFilter = !showFilter"
              ><i
                class="fa mr-2"
                :class="{ 'fa-filter': !showFilter, 'fa-times': showFilter }"
              ></i
            ></b-btn>
          </b-input-group-prepend>
          <b-form-input
            id="filter-input"
            v-model="filter"
            placeholder="Search..."
          />
          <b-input-group-append>
            <b-btn variant="danger" :disabled="!filter" @click="cleanFilter"
              ><i class="fa fa-trash"></i
            ></b-btn>
          </b-input-group-append>
        </b-input-group>
      </FormGroup>
      <!-- <b-col lg="1">
          <b-form-group
            label="Ordem:"
            label-for="filtro-ordem"
            label-size="sm"
            label-class="mb-0 font-weight-bold"
          >
            <b-form-select
              id="filtro-ordem"
              v-model="sortDirection"
              :options="['asc', 'desc', 'last']"
              label-size="sm"
              size="sm"
            >
            </b-form-select>
          </b-form-group>
        </b-col> -->
      <!-- <Grid cols="12 4">
          <FormGroup
            label="Ordenar"
            label_for="filtro-ordenar"
            v-slot="{ ariaDescribedby }"
          >
            <b-input-group>
              <b-form-select
                id="sort-by"
                v-model="sortBy"
                :options="sortOptions"
                :aria-describedby="ariaDescribedby"
                class="w-75"
              >
                <template #first>
                  <option value="">-- none --</option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                :disabled="!sortBy"
                :aria-describedby="ariaDescribedby"
              >
                <option :value="false">Asc</option>
                <option :value="true">Desc</option>
              </b-form-select>
            </b-input-group>
          </FormGroup>
        </Grid> -->
      <hr />
      <b-row class="d-flex justify-content-end my-2" v-if="itemsChecked.length">
        <Grid cols="12 5">
          <b-form-group
            label="Data do pagamento:"
            class="text-bold"
            label_for="data-pagamento"
            label-cols-sm="12"
            label-cols-md="6"
            label-cols-lg="6"
            label-align-sm="right"
          >
            <b-form-input id="per-page-select" type="date" v-model="dataPgto" />
          </b-form-group>
        </Grid>
        <Grid cols="12 2" class="my-1">
          <strong>Total à pagar: {{ totalAmountChecked }}</strong>
        </Grid>
        <Grid cols="12 2">
          <b-btn
            variant="success"
            block
            @click="$emit('pagar', { items: itemsChecked, dataPgto: dataPgto })"
            :disabled="dataPgto.length < 10"
            ><i class="fas fa-dollar-sign mr-1"></i>Pagar</b-btn
          >
        </Grid>
      </b-row>
      <b-table
        head-variant="dark"
        hover
        striped
        :items="itemsTable"
        :fields="fields"
        :current-page="currentPage"
        :filter-included-fields="filtrarPor"
        :per-page="perPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        aling-item="center"
        stacked="md"
        show-empty
        :empty-text="rowsEmpty"
        :empty-filtered-text="rowsEmpty"
        class="text-center"
        small
        @filtered="onFiltered"
      >
        <template #head(checkbox)>
          <b-form-checkbox v-model="marcarTodos" />
        </template>
        <template #cell(checkbox)="row">
          <b-form-checkbox v-model="itemsChecked" :value="row.item" />
        </template>
        <template #cell(actions)="row">
          <slot name="actions" :item="row.item" />
        </template>
        <template #row-details="{ item }">
          <b-card>
            <ul>
              <li v-for="(value, key) in item" :key="key">
                {{ key }}: {{ value }}
              </li>
            </ul>
          </b-card>
        </template>
      </b-table>
      <b-row class="p-4">
        <Grid cols="12 3" class="my-1">
          <b-form-group
            label="Items:"
            class="text-bold"
            label_for="per-page-select"
            label-cols-sm="6"
            label-cols-md="4"
            label-cols-lg="3"
            label-align-sm="right"
          >
            <b-select
              id="per-page-select"
              v-model="perPage"
              :options="pageOptions"
            />
          </b-form-group>
        </Grid>
        <Grid cols="12 9" class="my-1">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            class="my-0"
            align="fill"
          />
        </Grid>
      </b-row>
    </b-container>
    <SidebarFilter title="Filtro">
      <template v-slot:sidebar-content>
        <FormGroup label="Tipo" label_for="filtro-tipo">
          <b-form-radio-group
            id="filtro-tipo"
            v-model="tipo"
            :options="['ENTRADA', 'SAÍDA']"
            @change="onFilterTable('tipo', tipo)"
          />
        </FormGroup>
        <FormGroup label="Pago" label_for="filtro-pago">
          <b-form-select
            id="filtro-pago"
            value=""
            :options="[
              { text: 'Não', value: '0' },
              { text: 'Sim', value: '1' },
            ]"
            @change="onFilterTable('pago', $event)"
          >
            <template #first>
              <option value="">-- none --</option>
            </template>
          </b-form-select>
        </FormGroup>
        <FormGroup label="Grupo" label_for="filtro-grupo">
          <b-form-select
            id="filtro-grupo"
            label-size="sm"
            value=""
            :options="grupos || []"
            @change="onFilterTable('grupo', $event)"
          >
            <template #first>
              <option value="">-- none --</option>
            </template>
          </b-form-select>
        </FormGroup>
        <FormGroup label="Forma" label_for="filtro-forma">
          <b-form-select
            id="filtro-forma"
            value=""
            :options="formas || []"
            @change="onFilterTable('forma', $event)"
          >
            <template #first>
              <option value="">-- none --</option>
            </template>
          </b-form-select>
        </FormGroup>
        <FormGroup label="Despesa fixa?" label_for="despesa_fixa">
          <b-form-checkbox
            id="despesa_fixa"
            @change="onFilterTable('despesa_fixa', $event)"
            size="lg"
            switch
          />
        </FormGroup>
        <FormGroup
          label="Filtrar por"
          description="Desmarque para filtrar todos os dados"
          label-align-sm="right"
          class="filtrar-por"
        >
          <b-form-checkbox-group v-model="filtrarPor" class="mt-1">
            <b-form-checkbox value="descricao">Descrição</b-form-checkbox>
            <b-form-checkbox value="tag">TAG</b-form-checkbox>
          </b-form-checkbox-group>
        </FormGroup>
      </template>
    </SidebarFilter>
  </div>
</template>

<script>
import Grid from "@/common/layouts/Grid";
import { formas, grupos } from "@/common/constants/options";
import FormGroup from "@/common/form/FormGroup";
import SidebarFilter from "@/common/templates/SidebarFilter";

export default {
  name: "Table",
  props: ["items", "fields", "filterAll"],
  components: { FormGroup, Grid, SidebarFilter },
  data() {
    return {
      filter: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalAmount: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      itemsTable: [],
      itemsFiltered: [],
      rowsEmpty: "Nenhum registro encontrado.",
      grupos: [],
      formas: [],
      tipo: "SAÍDA",
      filterOn: [],
      filtrarPor: [],
      itemsChecked: [],
      dataPgto: "",
      showFilter: false,
    };
  },
  async mounted() {
    this.grupos = await grupos();
    this.formas = await formas(this.tipo);
  },
  computed: {
    pageOptions() {
      return [10, 15, 20, { value: this.items.length, text: "Mostrar todos" }];
    },
    sortOptions() {
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
    totalAmountChecked() {
      return Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.itemsChecked.reduce((a, b) => a + parseFloat(b.valor), 0));
    },
    marcarTodos: {
      get() {
        return this.itemsTable.length
          ? this.itemsChecked.length == this.itemsTable.length
          : false;
      },
      set(item) {
        var items = [];

        if (item) {
          this.itemsTable.map((i) => {
            items.push(i);
          });
        }
        this.itemsChecked = items;
      },
    },
  },
  watch: {
    items() {
      this.onFilterTable("tipo", this.tipo);
    },
    itemsTable() {
      this.onFiltered(this.itemsTable);
    },
    itemsChecked(items) {
      if (!items.length) this.dataPgto = "";
    },
  },
  methods: {
    cleanFilter() {
      this.filter = "";
      this.filtrarPor = [];
    },
    onFilterTable(key, val) {
      this.filterOn.push({ key, val });

      let filter = this.filterOn.reduce((arr, item) => {
        let data = arr.filter((data) => data.key !== item.key);
        return [...data, item];
      }, []);

      let itemsData = [...this.items];

      filter.filter((f) => {
        let { key, val } = f;
        if (val) {
          switch (key) {
            case "tipo":
              itemsData = itemsData.filter(({ meio }) => meio.tipo === val);

              break;
            case "grupo":
              itemsData = itemsData.filter(
                ({ categoria }) => categoria.grupo === val
              );
              break;
            case "despesa_fixa":
              itemsData = itemsData.filter(({ despesa_fixa }) => despesa_fixa);
              break;
            case "forma":
              // 1 = C.CREDITO; 3 = C.CREDITO (TERCEIROS)
              // 7 = C.CREDITO / PARCELAMENTO; 8 = C.CREDITO (TERCEIROS) / PARCELAMENTO
              if (val === 1) {
                itemsData = itemsData.filter(({ meio }) =>
                  [1, 7].includes(meio.id)
                );
              } else if (val === 3) {
                itemsData = itemsData.filter(({ meio }) =>
                  [3, 8].includes(meio.id)
                );
              } else {
                itemsData = itemsData.filter(({ meio }) => meio.id === val);
              }
              break;
            case "pago":
              itemsData = itemsData.filter(
                ({ pago, meio }) => pago === +val && meio.tipo === "SAÍDA"
              );
              break;
          }
        }
        this.itemsTable = itemsData;
      });
    },

    toggleDetails(row) {
      if (row._showDetails) {
        this.$set(row, "_showDetails", false);
      } else {
        this.items.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });

        this.$nextTick(() => {
          this.$set(row, "_showDetails", true);
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
      this.calculateAmount(filteredItems);
      this.$emit(
        "resumoLancamentos",
        `Release(s): ${filteredItems.length} | Amount: ${this.totalAmount}`
      );
    },
    calculateAmount(items) {
      this.totalAmount = Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(items.reduce((a, b) => a + parseFloat(b.valor), 0));
    },
  },
};
</script>

<style>
.table-content-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.table tr td {
  vertical-align: middle;
}

.input-group-prepend,
.input-group-append {
  background: #fff;
}

.input-group > .input-group-prepend > .input-group-text {
  background: #fff;
  border-right: none !important;
}

.input-group > .form-control:not(:first-child) {
  border-left: none !important;
}

.form-control:focus {
  border-color: #ced4da !important;
  outline: none !important;
  box-shadow: none !important;
}

.input-group-sm > .input-group-append > .btn {
  font-size: 0.8rem !important;
}

.filtrar-por label {
  font-weight: normal !important;
}
</style>