<template>
  <div>
    <b-form-group>
      <b-input-group>
        <b-input-group-prepend>
          <span class="input-group-text"><i class="fa fa-search"></i></span>
        </b-input-group-prepend>
        <b-form-input
          style="border-left: none"
          placeholder="Search..."
          v-model="filter"
        >
        </b-form-input>
      </b-input-group>
    </b-form-group>
    <b-table
      head-variant="dark"
      hover
      striped
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      aling-item="center"
      stacked="md"
      show-empty
      :empty-text="rowsEmpty"
      :empty-filtered-text="rowsEmpty"
      class="text-center"
      small
      @filtered="onFiltered"
    >
      <template #cell(actions)="row">
        <slot name="actions" :item="row.item" />
      </template>
      <template #row-details="{ item }">
        <b-card>
          <ul>
            <li v-for="(value, key) in item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul>
        </b-card>
      </template>
    </b-table>
    <b-row>
      <b-col md="4" sm="12" class="my-1">
        <b-form-group
          label="Items:"
          label-for="per-page-select"
          label-cols-sm="6"
          label-cols-md="4"
          label-cols-lg="3"
          label-align-sm="right"
          class="font-weight-bold mb-0"
        >
          <b-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col md="8" sm="12" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
          align="fill"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Table",
  props: ["items", "fields", "filterAll", "filterOn"],
  data() {
    return {
      filter: null,
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      totalAmount: 0,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      itemsFiltered: [],
      rowsEmpty: "Nenhum registro encontrado.",
    };
  },
  computed: {
    pageOptions() {
      return [10, 15, 20, { value: this.items.length, text: "Mostrar todos" }];
    },
  },
  watch: {
    items() {
      this.onFiltered(this.items);
    },
  },
  methods: {
    toggleDetails(row) {
      if (row._showDetails) {
        this.$set(row, "_showDetails", false);
      } else {
        this.items.forEach((item) => {
          this.$set(item, "_showDetails", false);
        });

        this.$nextTick(() => {
          this.$set(row, "_showDetails", true);
        });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style>
.table tr td {
  vertical-align: middle;
}

.input-group-prepend,
.input-group-append {
  display: flex;
  background: #fff;
}

.input-group > .input-group-prepend > .input-group-text {
  background: #fff;
  border-right: none;
}

.input-group > .form-control:not(:first-child) {
  border-left: none;
}

.form-control:focus {
  border-color: #ced4da !important;
  outline: none !important;
  box-shadow: none !important;
}
</style>