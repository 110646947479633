<template>
  <div class="tab-content">
    <div :class="`tab-pane fade ${active ? 'active show' : ''}`" :id="id">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "TabContent",
  props: ["id", "active"],
};
</script>

<style>
</style>