<template>
  <Grid :cols="cols">
    <div :class="`small-box bg-${color}`" @click="route ? $router.push(route) : ''">
      <div class="inner">
        <p>{{ title }}</p>
        <h3>{{ value }}</h3>
      </div>
      <div class="icon">
        <i :class="icon"></i>
      </div>
    </div>
  </Grid>
</template>

<script>
import Grid from "../layouts/Grid";

export default {
  name: "Box",
  components: { Grid },
  props: ["cols", "color", "icon", "value", "title", "route"],
};
</script>

<style>
.small-box {
  cursor: pointer;
  max-height: 100%;
}
</style>