<template>
  <div class="admin-category">
    <b-form>
      <b-row>
        <Grid cols="12 3" v-if="!categoria.id" class="text-center">
          <FormGroup label="Grupo já cadastrado?" label_for="grupo-cadastrado">
            <b-form-radio-group
              id="grupo-cadastrado"
              v-model="isExistsGrupo"
              :options="radioGroupos"
            />
          </FormGroup>
        </Grid>
        <Grid :cols="!categoria.id ? '12 4' : '12 6'">
          <FormGroup
            label="Grupo"
            id="categoria-grupo"
            label_for="categoria-grupo"
          >
            <div v-if="isExistsGrupo">
              <b-form-select
                id="categoria-grupo"
                @change="onGrupo"
                v-model="grupo"
                :options="grupos || []"
                :disabled="mode === 'remove' ? true : false"
              >
                <template #first>
                  <option value="">--- none ---</option>
                </template>
              </b-form-select>
            </div>
            <div v-else>
              <b-form-input
                id="categoria-grupo"
                type="text"
                v-model="categoria.grupo"
                required
                placeholder="Informe o Grupo..."
                :disabled="mode === 'remove' ? true : false"
              />
            </div>
          </FormGroup>
        </Grid>
        <Grid :cols="!categoria.id ? '12 5' : '12 6'">
          <FormGroup
            label="Subgrupo"
            id="categoria-subgrupo"
            label_for="categoria-subgrupo"
          >
            <b-form-input
              id="categoria-subgrupo"
              type="text"
              v-model="categoria.subgrupo"
              required
              placeholder="Informe o Subgrupo..."
              :disabled="mode === 'remove' ? true : false"
            />
          </FormGroup>
        </Grid>
      </b-row>
      <b-row>
        <b-col xs="12">
          <b-btn variant="primary" v-if="mode === 'save'" @click="save"
            >Salvar</b-btn
          >
          <b-btn variant="danger" v-if="mode === 'remove'" @click="remove"
            >Excluir</b-btn
          >
          <b-btn class="m-2" @click="reset">Cancelar</b-btn>
        </b-col>
      </b-row>
    </b-form>
    <hr />
    <Table
      :items="categorias"
      :fields="fields"
    >
      <template v-slot:actions="{ item }">
        <b-btn-group size="sm">
          <b-btn variant="warning" @click="setCategoria(item)">
            <i class="fa fa-pen"></i>
          </b-btn>
          <b-btn variant="danger" @click="setCategoria(item, 'remove')">
            <i class="fa fa-trash"></i>
          </b-btn>
        </b-btn-group>
      </template>
    </Table>
  </div>
</template>

<script>
import { showError } from "@/functions";
import Table from "../../common/layouts/Table";
import FormGroup from "../../common/form/FormGroup";
import Grid from "../../common/layouts/Grid";

export default {
  name: "AdminCategory",
  components: { Table, Table, FormGroup, Grid },
  data() {
    return {
      mode: "save",
      categoria: {},
      categorias: [],
      grupo: "",
      grupos: [],
      fields: [
        { key: "id", label: "#" },
        { key: "subgrupo", label: "Subgrupo", sortable: true },
        { key: "grupo", label: "Grupo", sortable: true },
        { key: "actions", label: "Ações" },
      ],
      isExistsGrupo: true,
      radioGroupos: [
        { text: "Sim", value: true },
        { text: "Não", value: false },
      ],
    };
  },
  methods: {
    async getGrupos() {
      return (this.grupos = await this.$api
        .get(`${process.env.VUE_APP_CATEGORIAS_V1}/grupos`)
        .then((res) => res.data.map((item) => item.grupo)));
    },
    onGrupo() {
      return (this.categoria = { ...this.categoria, grupo: this.grupo });
    },
    async getCategorias() {
      this.$store.commit("setLoading", true);
      return await this.$api
        .get(`${process.env.VUE_APP_CATEGORIAS_V1}`)
        .then((res) => {
          this.categorias = res.data;
          this.$store.commit("setLoading", false);
        });
    },
    setCategoria(item, mode = "save") {
      this.mode = mode;
      this.grupo = item.grupo;
      this.categoria = { ...item };
    },
    reset() {
      this.mode = "save";
      this.grupo = "";
      this.categoria = {};
      this.isExistsGrupo = true;
      this.getCategorias();
      this.$store.commit("setLoading", false);
    },
    save() {
      const method = this.categoria.id ? "put" : "post";
      const baseURL = this.categoria.id
        ? `${process.env.VUE_APP_CATEGORIAS_V1}/${this.categoria.id}`
        : `${process.env.VUE_APP_CATEGORIAS_V1}`;

      this.$store.commit("setLoading", true);
      this.$api[method](baseURL, this.categoria)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.reset();
        })
        .catch(showError);
    },
    remove() {
      const id = this.categoria.id;
      this.$api
        .delete(`${process.env.VUE_APP_CATEGORIAS_V1}/${id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          this.reset();
        })
        .catch(showError);
    },
  },
  async mounted() {
    await this.getCategorias();
    await this.getGrupos();
  },
};
</script>

<style>
</style>