<template>
  <li class="nav-item">
    <a :href="route" class="nav-link">
      <i :class="`nav-icon ${icon}`"></i>
      <p>{{ label }}</p>
    </a>
  </li>
</template>

<script>
export default {
  name: "MenuItem",
  props: ["route", "icon", "label"],
};
</script>

<style>
</style>