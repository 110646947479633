<template>
  <b-modal :id="idModal">
    <template #modal-title>{{ titleModal }}</template>
    <slot />
    <template #modal-footer>
      <slot name="modal-footer" />
    </template>
  </b-modal>
</template>

<script>
export default {
  props: ["idModal", "titleModal"],
  name: "Modal",
};
</script>

<style>
</style>