<template>
  <div>
    <ContentHeader
      icon="fa fa-dollar"
      :title="mesAno | dataMesAno"
      subtitle="Summary of releases in the month"
    />
    <b-container fluid>
      <b-card-group deck>
        <div class="card">
          <div class="card-header border-0">
            <div class="d-flex justify-content-between">
              <h3 class="card-title">
                <span class="text-bold">{{ lancamentos.despesas.length }}</span>
                Lançamentos
              </h3>
              <a @click.prevent="lancamentosMes"
                ><i class="fas fa-search" style="cursor: pointer"></i
              ></a>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <p class="d-flex flex-column">
                <span class="text-primary text-bold text-lg">
                  {{ lancamentos.receita }}
                </span>
                <span class="text-muted">Receitas</span>
              </p>
              <p class="ml-auto d-flex flex-column text-right">
                <span class="text-danger text-bold text-lg">{{
                  lancamentos.gasto
                }}</span>
                <span class="text-muted">Gastos</span>
              </p>
            </div>

            <div class="position-relative mb-4">
              <Pie
                id="graficos_categorias"
                :items="graficos.categorias"
                tooltipLabel="currency"
                height="70"
                width="100"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header border-0">
            <div class="d-flex justify-content-between">
              <h3 class="card-title">Meios de pagamento</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <p class="d-flex flex-column">
                <span
                  class="text-bold text-lg"
                  :style="`color: ${total.saldo > 0 ? 'green' : 'red'}`"
                >
                  {{ lancamentos.saldo }}</span
                >
                <span class="text-muted">Receitas (-) Gastos</span>
              </p>
            </div>
            <div class="position-relative">
              <Bars
                :items="graficos.formas"
                height="70"
                width="100"
                type="horizontalBar"
              />
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header border-0">
            <div class="d-flex justify-content-between">
              <h3 class="card-title">Pagamentos</h3>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <p class="d-flex flex-column">
                <span class="text-primary text-bold text-lg">
                  {{ lancamentos.pagos }}
                </span>
                <span class="text-muted">Realizados</span>
              </p>
              <p class="ml-auto d-flex flex-column text-right">
                <span class="text-danger text-bold text-lg">{{
                  lancamentos.nao_pagos
                }}</span>
                <span class="text-muted">Pendentes</span>
              </p>
            </div>

            <div class="position-relative mb-4">
              <Pie
                id="graficos_pgtos"
                :items="graficos.pgtos"
                tooltipLabel="percentual"
                height="70"
                width="100"
                type="doughnut"
              />
            </div>
          </div>
        </div>
      </b-card-group>
    </b-container>
  </div>
</template>

<script>
import ContentHeader from "../../common/templates/ContentHeader";
import Grid from "../../common/layouts/Grid";
import Pie from "../../common/charts/Pie";
import Bars from "../../common/charts/Bars";
import { showError, currencyMask } from "@/functions";

export default {
  name: "LancamentoMesResumo",
  components: { ContentHeader, Grid, Pie, Bars },
  data() {
    return {
      graficos: {
        categorias: [],
        formas: [],
        pgtos: [],
      },
      lancamentos: {
        despesas: [],
        receita: "",
        gasto: "",
        saldo: "",
        pagos: "",
        nao_pagos: "",
      },
      total: {
        receitas: 0,
        gastos: 0,
        saldo: 0,
      },
    };
  },
  watch: {
    $route(to) {
      this.reset();
      const { mes, ano } = to.params;

      this.getResumoCategorias(mes, ano);
      this.getResumoFormas(mes, ano);
      this.getLancamentos(mes, ano);
    },
  },
  computed: {
    mesAno() {
      return `${this.$route.params.mes}/${this.$route.params.ano}`;
    },
  },
  methods: {
    lancamentosMes() {
      const { mes, ano } = this.$route.params;
      this.$router.push({
        name: "lancamento.mes.detalhes",
        params: { mes, ano },
      });
    },
    reset() {
      this.graficos = {
        categorias: [],
        formas: [],
        pgtos: [],
      };

      this.lancamentos = {
        despesas: [],
        receita: "",
        gasto: "",
        saldo: "",
        pagos: "",
        nao_pagos: "",
      };
      this.total = {
        receitas: 0,
        gastos: 0,
        saldo: 0,
      };
    },
    async getResumoCategorias(mes, ano) {
      return await this.$api
        .get(
          `${process.env.VUE_APP_LANCAMENTOS_V1}/${mes}/mes/${ano}/ano/resumo_mes_grupos`
        )
        .then((res) => {
          this.graficos.categorias = res.data
            .filter((f) => f.grupo !== "RECEITA")
            .map((item) => ({
              label: item.grupo,
              value: item.total,
            }));
        })
        .catch(showError);
    },
    async getResumoFormas(mes, ano) {
      return await this.$api
        .get(
          `${process.env.VUE_APP_LANCAMENTOS_V1}/${mes}/mes/${ano}/ano/resumo_mes_formas`
        )
        .then((res) => {
          this.graficos.formas = res.data.map((item) => ({
            label: item.forma,
            value: item.total,
          }));
        })
        .catch(showError);
    },
    async getLancamentos(mes, ano) {
      this.$store.commit("setLoading", true);
      return await this.$api
        .get(`${process.env.VUE_APP_LANCAMENTOS_V1}/${mes}/mes/${ano}/ano`)
        .then((res) => {
          this.$store.commit("setLoading", false);

          this.lancamentos.despesas = res.data.filter(
            (item) => item.meio.tipo === "SAÍDA"
          );

          this.total.receitas = res.data
            .filter((item) => item.meio.tipo == "ENTRADA")
            .reduce((a, b) => a + parseFloat(b.valor), 0);

          this.total.gastos = res.data
            .filter((item) => item.meio.tipo == "SAÍDA")
            .reduce((a, b) => a + parseFloat(b.valor), 0);

          this.lancamentos.pagos = res.data.filter(
            ({ pago, meio }) => meio.tipo !== "ENTRADA" && pago == 1
          ).length;

          this.lancamentos.nao_pagos = res.data.filter(
            ({ pago, meio }) => meio.tipo !== "ENTRADA" && pago == 0
          ).length;

          this.graficos.pgtos.push(
            {
              label: "NÃO PAGOS",
              value:
                parseFloat(
                  this.lancamentos.nao_pagos / this.lancamentos.despesas.length
                ).toFixed(2) * 100,
              color: "rgb(255, 99, 132)",
            },
            {
              label: "PAGOS",
              value:
                parseFloat(
                  this.lancamentos.pagos / this.lancamentos.despesas.length
                ).toFixed(2) * 100,
              color: "rgb(54, 162, 235)",
            }
          );

          this.total.saldo = this.total.receitas - this.total.gastos;

          this.lancamentos.receita = currencyMask(this.total.receitas);
          this.lancamentos.gasto = currencyMask(this.total.gastos);
          this.lancamentos.saldo = currencyMask(
            this.total.receitas - this.total.gastos
          );

          if (this.lancamentos.despesas.length) {
            this.$store.commit("setLoading", false);
          }
        })
        .catch((e) => {
          showError(e);
          this.$store.commit("setLoading", false);
        });
    },
  },

  mounted() {
    const { mes, ano } = this.$route.params;
    this.getResumoCategorias(mes, ano);
    this.getResumoFormas(mes, ano);
    this.getLancamentos(mes, ano);
  },
};
</script>

<style>
</style>