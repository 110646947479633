<template>
  <div class="nav-item">
    <li class="nav-header">{{ nav_header }}</li>
    <li
      v-for="(menu, key) in items"
      :key="key"
      :label="menu.label"
      :icon="menu.icon"
      @click.stop="toggleMenu(key)"
      class="nav-item"
      :class="{ 'menu-is-opening menu-open': menu.isOpen }"
    >
      <a href="javascript:void(0)" class="nav-link">
        <i :class="`nav-icon ${menu.icon}`"></i>
        <p>
          {{ menu.label }}
          <i class="fas fa-angle-left right"></i>
        </p>
      </a>
      <ul class="nav nav-treeview">
        <li
          class="nav-item"
          v-for="(submenu, key) in menu.children"
          :key="key"
          :label="submenu.label"
          :icon="submenu.icon"
          :route="submenu.route"
        >
          <a
            :href="submenu.route"
            class="nav-link"
          >
            <i :class="`nav-icon ${submenu.icon}`"></i>
            <p>{{ submenu.label }}</p>
          </a>
        </li>
      </ul>
    </li>
  </div>
</template>

<script>
export default {
  name: "MenuTree",
  props: ["nav_header", "items"],
  methods: {
    toggleMenu(key) {
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i] !== this.items[key]) {
          this.items[i].isOpen = false;
        } else {
          this.items[key].isOpen = !this.items[key].isOpen;
        }
      }
    },
  },
};
</script>

<style>
.nav-item {
  cursor: pointer;
}
</style>