<template>
  <div v-if="$route.name === 'auth'">
    <Auth />
  </div>
  <div v-else class="wrapper">
    <Header />
    <Sidebar />
    <Loading v-if="isLoading" />
    <Content />
    <Footer />
  </div>
</template>

<script>
import { userKey } from "@/components/auth/data";

import Auth from "@/components/auth/Auth";
import Header from "@/common/templates/Header";
import Sidebar from "@/common/templates/Sidebar";
import Content from "@/common/templates/Content";
import Footer from "@/common/templates/Footer";
import Loading from "@/common/templates/Loading";

import { mapState } from "vuex";

export default {
  name: "App",
  components: { Auth, Header, Sidebar, Content, Footer, Loading },
  computed: mapState(["isLoading"]),
  methods: {
    userData() {
      this.$store.commit("setUser", null);
      if (!userKey) {
        document.cookie = `userKey=; Max-Age=0; path=/; domain=${location.hostname}`;
        this.$router.push("/auth").catch(() => {});
        return;
      } else {
        this.$store.commit("setUser", JSON.parse(userKey));
      }
    },
  },
  created() {
    this.userData();
  },
};
</script>

<style>
* {
  font-family: "Source Sans Pro";
}
</style>