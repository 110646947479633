<template>
  <div>
    <ContentHeader title="Dashboard" hideHistory="true" />
      <b-alert
        variant="danger"
        :show="dashboard.pagamentos_pendentes > 0 ? true : false"
        ><i class="fas fa-exclamation-circle mr-2" /><a
          href="/pagamento_pendente"
          class="alert-link"
          >{{ dashboard.pagamentos_pendentes }}</a
        >
        pagamento(s) pendente(s).</b-alert
      >
      <div class="grid-dashboard">
        <Box
          v-for="(item, key) in dashboard.boxes"
          cols="12 4"
          :key="key"
          :title="item.title"
          :value="
            key == 0 && !mostrarValores ? exibirValor(item.value) : item.value
          "
          :icon="item.icon"
          :color="item.color"
          :route="item.route"
        />
      </div>
      <div class="pb-3">
        <small>{{ `${!mostrarValores ? "Exibir" : "Ocultar"} valores` }}</small>
        <i
          :class="`fas fa-${!mostrarValores ? 'eye' : 'eye-slash'} ml-2`"
          style="cursor: pointer"
          @click="exibirValores"
        ></i>
      </div>
      <b-card no-body class="overflow-hidden" v-if="dashboard.regra503020">
        <b-row>
          <b-col md="4">
            <b-card-body>
              <b-card-title
                ><i class="fas fa-dollar-sign mr-2"></i
                ><strong>Regra 60 30 10</strong></b-card-title
              >
              <b-card-text>
                A ideia é dividir a renda líquida mensal em três partes (60%,
                30% e 10%) tendo como principal objetivo o equilíbrio do
                orçamento entre:
                <ul>
                  <li><strong>Despesas fixas (60%)</strong></li>
                  <li><strong>Despesas variáveis (30%)</strong></li>
                  <li><strong>Investimentos (10%)</strong></li>
                </ul>
              </b-card-text>
            </b-card-body>
          </b-col>
          <b-col md="8">
            <b-card-body>
              <b-card-title>
                <strong
                  ><i class="fas fa-calendar-alt mr-2"></i
                  >{{
                    `Orçamento ${$moment()
                      .add(1, "M")
                      .format("MMM/YYYY")
                      .toUpperCase()}`
                  }}</strong
                >
              </b-card-title>
              <b-card-text>
                <b-table-simple
                  striped
                  hover
                  class="text-center"
                  small
                  bordered
                  responsive
                >
                  <b-thead head-variant="dark">
                    <b-tr>
                      <b-th
                        v-for="(head, key) in fields"
                        :class="head.class"
                        :key="key"
                        >{{ head.title }}</b-th
                      >
                    </b-tr>
                  </b-thead>
                  <b-tbody>
                    <b-tr v-for="(item, key) in items_regra503020" :key="key">
                      <b-th variant="secondary">{{ item.item }}</b-th>
                      <b-td
                        :colspan="key === 2 ? 3 : null"
                        :class="item.class"
                        >{{ exibirValor(item.previsao) }}</b-td
                      >
                      <b-td v-if="item.atual" :class="item.class">{{
                        exibirValor(item.atual)
                      }}</b-td>
                      <b-td v-if="item.diferenca" :variant="item.bgColor">{{
                        exibirValor(item.diferenca)
                      }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
                <p class="d-flex justify-content-end text-bold text-primary">
                  Renda líquida:
                  {{ exibirValor(dashboard.regra503020.renda_liquida) }}
                </p>
              </b-card-text>
            </b-card-body>
          </b-col>
        </b-row>
      </b-card>
  </div>
</template>

<script>
import ContentHeader from "../../common/templates/ContentHeader";
import { currencyMask } from "@/functions";
import Box from "../../common/widgets/Box";

export default {
  name: "Home",
  components: { ContentHeader, Box },
  data() {
    return {
      dashboard: [],
      fields: [
        { title: "Item" },
        { title: "Previsão", class: "d-none d-sm-table-cell" },
        { title: "Atual", class: "d-none d-sm-table-cell" },
        { title: "Previsão (-) Atual" },
      ],
      items_regra503020: [],
      mostrarValores: false,
    };
  },
  watch: {
    "dashboard.regra503020"(items) {
      const { investimentos, renda_liquida } = items;
      this.dashboard.regra503020.renda_liquida = currencyMask(renda_liquida);
      this.dashboard.regra503020.investimentos = currencyMask(investimentos);
    },
  },
  methods: {
    exibirValores() {
      this.mostrarValores = !this.mostrarValores;
    },
    exibirValor(valor) {
      return !this.mostrarValores ? "R$ ******" : valor;
    },
    async getDashboard() {
      this.$store.commit("setLoading", true);
      await this.$api
        .get(`${process.env.VUE_APP_RESUMOS_V1}/dashboard`)
        .then((res) => {
          this.dashboard = res.data;
          (this.items_regra503020 = [
            {
              item: "Despesas fixas",
              previsao: this.dashboard.regra503020.despesas_fixas.previsao,
              atual: this.dashboard.regra503020.despesas_fixas.atual,
              diferenca: currencyMask(
                this.dashboard.regra503020.despesas_fixas.diferenca
              ),
              bgColor:
                this.dashboard.regra503020.despesas_fixas.diferenca > 0
                  ? "success"
                  : "danger",
              class: "d-none d-sm-table-cell",
            },
            {
              item: "Despesas variáveis",
              previsao: this.dashboard.regra503020.despesas_variaveis.previsao,
              atual: this.dashboard.regra503020.despesas_variaveis.atual,
              diferenca: currencyMask(
                this.dashboard.regra503020.despesas_variaveis.diferenca
              ),
              bgColor:
                this.dashboard.regra503020.despesas_variaveis.diferenca > 0
                  ? "success"
                  : "danger",
              class: "d-none d-sm-table-cell",
            },
            {
              item: "Investimentos",
              previsao: currencyMask(this.dashboard.regra503020.investimentos),
            },
          ]),
            this.$store.commit("setLoading", false);
        });
    },
  },
  mounted() {
    this.getDashboard();
  },
};
</script>

<style>
.grid-dashboard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.alert a {
  text-decoration: none !important;
}

.alert a:hover {
  text-decoration: underline !important;
}
</style>