<template>
  <ol class="breadcrumb float-sm-right">
    <li class="breadcrumb-item">
      <a href="/home">Home</a>
    </li>
    <li class="breadcrumb-item active">
      <span>{{ label }}</span>
    </li>
  </ol>
</template>

<script>
export default {
  name: "BreadCrumb",
  props: ["label"],
};
</script>

<style>
</style>