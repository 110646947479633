<template>
  <nav class="mt-2">
    <ul
      class="nav nav-pills nav-sidebar flex-column nav-child-indent"
      data-widget="treeview"
      role="menu"
      collapse="false"
    >
      <li class="nav-header">LANÇAMENTO</li>
      <MenuItem route="/lancamento" icon="fas fa-plus" label="Incluir" />
      <MenuItem
      route="/lancamento_fixo"
      icon="fas fa-home"
      label="Lançamento Fixo"
      />
      <MenuItem route="/lancamento_anual" icon="fas fa-calendar-alt" label="Lançamento Anual" />
      <!-- <MenuTree v-if="menus.length" nav_header="LANÇAMENTOS" :items="menus" /> -->      
    </ul>
  </nav>
</template>

<script>
import MenuItem from "./MenuItem";
import MenuTree from "./MenuTree";

export default {
  name: "Menu",
  components: { MenuItem, MenuTree },
  data() {
    return {
      menus: [],
    };
  },
  methods: {
    async getMenu() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro",
      ];

      this.menus = await this.$api
        .get(`${process.env.VUE_APP_LANCAMENTOS_V1}/anos`)
        .then((res) => [
          {
            label: `${new Date().getFullYear()}`,
            icon: "fas fa-calendar-alt",
            isOpen: false,
            children: meses.map((mes, idx) => ({
              label: mes,
              icon: "fas fa-coins",
              route: `/lancamento/${idx + 1}/mes/${this.$moment().format(
                "YYYY"
              )}/ano/resumo`,
            })),
          },
          {
            label: "Anos",
            icon: "fas fa-calendar-alt",
            isOpen: false,
            children: res.data.map((ano) => ({
              label: ano,
              icon: "fa fa-calendar-alt",
              route: `/lancamento/${ano}/ano`,
            })),
          },
        ]);
    },
  },
  async mounted() {
    // await this.getMenu();
  },
};
</script>

<style>
.nav-pills .nav-link:not(.active):hover {
  color: #fff !important;
}
</style>