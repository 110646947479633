<template>
  <div>
    <ContentHeader
      icon="fa fa-dollar"
      title="Release Data"
      :subtitle="subtitulo_lancamento"
    />
    <b-container fluid>
      <b-form class="pb-4">
        <b-row>
          <Grid cols="12 4">
            <FormGroup
              label="Data do lançamento"
              label_for="lancamento-data-lancamento"
            >
              <b-form-input
                id="lancamento-data-lancamento"
                type="date"
                v-model="lancamento.data_lancamento"
                required
              />
            </FormGroup>
          </Grid>
          <Grid :cols="parcelamento ? '12 4' : '12 3'">
            <FormGroup label="Tipo" label_for="lancamento-tipo">
              <b-form-radio-group
                id="lancamento-tipo"
                v-model="tipo"
                :options="['ENTRADA', 'SAÍDA']"
              />
            </FormGroup>
          </Grid>
          <Grid :cols="parcelamento ? '12 4' : '12 3'">
            <FormGroup
              :label="
                (!lancamento.id && parcelamento) || divisao_valor
                  ? 'Valor Total'
                  : 'Valor'
              "
              label_for="lancamento-valor"
            >
              <b-form-input
                id="lancamento-valor"
                type="number"
                v-model="lancamento.valor"
                :readonly="!lancamento.id && parcelamento"
                required
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 2" v-if="!parcelamento">
            <FormGroup
              label="Dividir valor?"
              label_for="lancamento-divisao-valor"
            >
              <b-form-checkbox
                id="lancamento-divisao-valor"
                v-model="divisao_valor"
                size="lg"
                switch
              />
            </FormGroup>
          </Grid>
        </b-row>
        <b-row>
          <Grid cols="12 4">
            <FormGroup label="Forma" label_for="lancamento-forma">
              <b-form-select
                id="lancamento-forma"
                v-model="forma"
                :options="meios || []"
                @change="onLancamento('meio_id')"
              >
                <template #first>
                  <option value="">--- none ---</option>
                </template>
              </b-form-select>
            </FormGroup>
          </Grid>
          <template v-if="(!lancamento.id && parcelamento) || divisao_valor">
            <Grid cols="12 4">
              <FormGroup
                :label="parcelamento ? `Qtd. Parcelas` : 'Dividir por'"
                label_for="lancamento-dividir-valor"
              >
                <b-form-input
                  id="lancamento-dividir-valor"
                  type="number"
                  v-model="dividir_valor"
                  required
                />
              </FormGroup>
            </Grid>
            <Grid cols="12 4">
              <FormGroup
                :label="parcelamento ? `Valor Parcela` : 'Valor Divisão'"
                label_for="lancamento-valor-divisao"
              >
                <b-form-input
                  id="lancamento-valor-divisao"
                  type="number"
                  :value="
                    dividir_valor
                      ? parseFloat(lancamento.valor / dividir_valor).toFixed(2)
                      : ''
                  "
                  readonly
                  required
                />
              </FormGroup>
            </Grid>
          </template>
        </b-row>
        <b-row>
          <Grid cols="12 4">
            <FormGroup label="Grupo" label_for="lancamento-grupo">
              <b-form-select
                id="lancamento-grupo"
                v-model="grupo"
                @change="onLancamento('grupo')"
                :options="grupos || []"
                :disabled="tipo === 'ENTRADA'"
              >
                <template #first>
                  <option value="">--- none ---</option>
                </template>
              </b-form-select>
            </FormGroup>
          </Grid>
          <Grid cols="12 4">
            <FormGroup label="Subgrupos" label_for="lancamento-subgrupo">
              <b-form-select
                id="lancamento-subgrupo"
                v-model="subgrupo"
                @change="onLancamento('categoria_id')"
                :options="subgrupos || []"
              >
                <template #first>
                  <option value="">--- none ---</option>
                </template>
              </b-form-select>
            </FormGroup>
          </Grid>
          <Grid cols="12 4">
            <FormGroup label="TAG" label_for="lancamento-tag">
              <b-form-input
                id="lancamento-tag"
                type="text"
                v-model="lancamento.tag"
                required
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 12">
            <FormGroup label="Descrição" label_for="lancamento-descricao">
              <b-form-textarea
                id="lancamento-descricao"
                v-model="lancamento.descricao"
                placeholder="Descreva os dados do lançamento..."
                rows="2"
                max-rows="4"
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 4">
            <FormGroup
              label="Despesa fixa?"
              label_for="lancamento-despesa_fixa"
            >
              <b-form-checkbox
                id="lancamento-despesa_fixa"
                v-model="despesa_fixa"
                @change="onLancamento('despesa_fixa')"
                size="lg"
                switch
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 4">
            <FormGroup label="Já foi pago?" label_for="lancamento-pago">
              <b-form-checkbox
                id="lancamento-pago"
                v-model="pago"
                @change="onLancamento('pago')"
                size="lg"
                switch
              />
            </FormGroup>
          </Grid>
          <Grid cols="12 4">
            <FormGroup
              :label="
                pago ? 'Data do pagamento' : 'Previsão de pagamento (+/-)'
              "
              label_for="lancamento-data-deposito-pagamento"
            >
              <b-form-input
                id="lancamento-data-deposito-pagamento"
                type="date"
                v-model="lancamento.data_deposito_pagamento"
                required
              />
            </FormGroup>
          </Grid>
        </b-row>
        <hr />
        <div class="d-flex justify-content-end">
          <b-btn
            variant="success"
            block
            :class="`px-0 ${this.$mq !== 'sm' ? 'w-25' : ''}`"
            @click="save"
          >
            <i class="fa fa-check"></i>
            Salvar
          </b-btn>
        </div>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import ContentHeader from "../../common/templates/ContentHeader";
import FormGroup from "../../common/form/FormGroup";
import Grid from "../../common/layouts/Grid";
import { showError } from "@/functions";
import { formas, grupos, subgrupos } from "../../common/constants/options";

export default {
  name: "LancamentoDetalhes",
  components: { ContentHeader, FormGroup, Grid },
  data() {
    return {
      subtitulo_lancamento: "New Release",
      parcelamentos_id: [7, 8],
      lancamento: {
        despesa_fixa: 0,
        pago: 0,
      },
      tipo: "SAÍDA",
      forma: "",
      grupo: "",
      subgrupo: "",
      dividir_valor: "",
      despesa_fixa: false,
      pago: false,
      divisao_valor: false,
      grupos: [],
      formas: [],
      subgrupos: [],
      lancamentos_futuros: [],
    };
  },
  computed: {
    meios() {
      return this.formas.map((meio) => meio);
    },
    parcelamento() {
      return this.parcelamentos_id.includes(this.forma);
    },
  },
  methods: {
    save() {
      const method = this.lancamento.id ? "put" : "post";
      const baseURL = this.lancamento.id
        ? `${process.env.VUE_APP_LANCAMENTOS_V1}/${this.lancamento.id}`
        : `${process.env.VUE_APP_LANCAMENTOS_V1}`;

      this.$store.commit("setLoading", true);
      if (method === "post") {
        //LANÇAMENTO C/ DIVISÃO DE VALOR
        if (this.divisao_valor) {
          this.lancamento = {
            ...this.lancamento,
            descricao: `${this.lancamento.descricao} (${Intl.NumberFormat(
              "pt-BR",
              {
                style: "currency",
                currency: "BRL",
              }
            ).format(this.lancamento.valor)}/${this.dividir_valor})`,
            valor: parseFloat(
              this.lancamento.valor / this.dividir_valor
            ).toFixed(2),
          };
        }
        if (!this.divisao_valor || this.divisao_valor) {
          this.$api
            .post(baseURL, this.lancamento)
            .then(() => {
              this.$router.push("/home").catch(() => {});
              this.$toasted.global.defaultSuccess();
            })
            .catch((e) => {
              showError(e);
              this.$store.commit("setLoading", false);
            });
        }
        if (this.parcelamento) {
          //LANÇAMENTO PARCELADO
          for (let count = 1; count <= this.dividir_valor; count++) {
            this.lancamentos_futuros.push({
              ...this.lancamento,
              data_deposito_pagamento: this.$moment()
                .add(count, "M")
                .format("YYYY-MM-DD"),
              descricao: `${this.lancamento.descricao} ${count}/${this.dividir_valor}`,
              valor: parseFloat(
                this.lancamento.valor / this.dividir_valor
              ).toFixed(2),
            });
          }
          this.lancamentos_futuros.map((lancamento, parcela) => {
            this.$api[method](baseURL, lancamento)
              .then(() => {
                if (parcela + 1 == this.dividir_valor) {
                  this.$toasted.global.defaultSuccess();
                  this.$store.commit("setLoading", false);
                  this.$router.push("/home").catch(() => {});
                }
              })
              .catch((e) => {
                showError(e);
                this.$store.commit("setLoading", false);
              });
          });
        }
      } else {
        this.$api[method](baseURL, this.lancamento)
          .then(() => {
            this.$router.push("/home").catch(() => {});
            this.$toasted.global.defaultSuccess();
          })
          .catch((e) => {
            showError(e);
            this.$store.commit("setLoading", false);
          });
      }
    },
    getLancamento(id) {
      this.$api
        .get(`${process.env.VUE_APP_LANCAMENTOS_V1}/${id}`)
        .then((res) => {
          this.lancamento = res.data;
          this.forma = res.data.meio.id;
          this.grupo = res.data.categoria.grupo;
          this.subgrupo = res.data.categoria.id;
          this.tipo = res.data.meio.tipo;
          this.despesa_fixa = res.data.despesa_fixa === 0 ? false : true;
          this.pago = res.data.pago === 0 ? false : true;
          this.$store.commit("setLoading", false);
        })
        .catch(() => {
          showError, this.$store.commit("setLoading", false);
        });
    },
    onLancamento(col) {
      switch (col) {
        case "meio_id":
          if (!this.parcelamento) {
            this.dividir_valor = "";
          }

          if (this.parcelamento) {
            this.divisao_valor = false;
          }

          this.lancamento = { ...this.lancamento, [col]: this.forma };
          break;
        case "categoria_id":
          this.lancamento = { ...this.lancamento, [col]: this.subgrupo };
          break;
        case "despesa_fixa":
          this.lancamento = {
            ...this.lancamento,
            [col]: !this.despesa_fixa ? 0 : 1,
          };
          break;
        case "pago":
          this.lancamento = {
            ...this.lancamento,
            [col]: !this.pago ? 0 : 1,
          };
          break;
        case "grupo":
          this.subgrupo = "";
          this.lancamento.categoria_id = "";
          break;
      }
    },
    reset() {
      this.forma = "";
      this.grupo = "";
      this.subgrupo = "";
      this.lancamento = {};
      this.subtitulo_lancamento = "New Release";
    },
  },
  watch: {
    $route() {
      this.reset();
    },
    async tipo(tipo) {
      //this.lancamento.meio_id = "";
      this.forma = "";
      this.grupo = "";
      this.formas = await formas(tipo);
      if (tipo === "ENTRADA") {
        this.grupo = "Receita";
        this.forma = this.lancamento.meio_id;
        this.lancamento.categoria_id = this.subgrupo;
        this.lancamento.data_deposito_pagamento =
          this.lancamento.data_lancamento;
      }
    },
    async grupo(grupo) {
      if (grupo) {
        this.subgrupos = await subgrupos(this.grupo);
      } else {
        this.subgrupo = "";
        this.subgrupos = [];
      }
    },
  },
  async mounted() {
    this.$store.commit("setLoading", true);
    this.grupos = await grupos();
    this.formas = await formas(this.tipo);
    const { id } = this.$route.params || [];
    if (id) {
      this.subtitulo_lancamento = "Edit Release";
      this.getLancamento(id);
    } else {
      this.$store.commit("setLoading", false);
    }
  },
};
</script>

<style>
</style>