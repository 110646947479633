<template>
  <b-row>
    <slot />
  </b-row>
</template>

<script>
export default {
  name: "Row"
}
</script>

<style>

</style>