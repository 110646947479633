<template>
  <div>
    <ContentHeader
      icon="fa fa-calendar"
      :title="mesAno | dataMesAno"
      :subtitle="resumo"
    />
    <TableLancamento
      :items="lancamentos"
      :fields="fields"
      ref="table"
      @resumoLancamentos="resumo = $event"
      @pagar="pagarLancamentos($event.items, $event.data_pgto)"
    >
      <template v-slot:actions="{ item }">
        <b-btn-group size="sm">
          <b-btn variant="primary" @click="$refs.table.toggleDetails(item)">
            <i
              :class="[
                {
                  'fa fa-caret-down': !item._showDetails,
                  'fa fa-caret-up': item._showDetails,
                },
              ]"
            ></i>
          </b-btn>
          <b-btn
            variant="warning"
            @click="$router.push(`/lancamento/${item.id}`)"
          >
            <i class="fa fa-pen"></i>
          </b-btn>
          <b-btn variant="danger" @click="modalDelete(item)">
            <i class="fa fa-trash"></i>
          </b-btn>
        </b-btn-group>
      </template>
    </TableLancamento>
    <Modal idModal="delete-release" titleModal="Excluir Lançamento">
      <dl class="row">
        <dt class="col-sm-4">Data Lançamento</dt>
        <dd class="col-sm-8">
          {{ $moment(lancamento.data_lancamento).format("DD/MM/YYYY") }}
        </dd>
        <dt class="col-sm-3">Descrição</dt>
        <dd class="col-sm-9">
          {{ lancamento.descricao }}
        </dd>
        <dt class="col-sm-3">Valor</dt>
        <dd class="col-sm-9">
          {{ lancamento.valor }}
        </dd>
      </dl>
      <template v-slot:modal-footer>
        <b-btn @click="$bvModal.hide('delete-release')"> Cancelar </b-btn>
        <b-btn variant="primary" @click="excluirLancamento(lancamento.id)">
          Ok
        </b-btn>
      </template>
    </Modal>
  </div>
</template>

<script>
import TableLancamento from "./TableLancamento";
import ContentHeader from "../../common/templates/ContentHeader";
import Modal from "../../common/layouts/Modal";
import { showError, currencyMask } from "@/functions";

export default {
  name: "LancamentoMes",
  components: { ContentHeader, TableLancamento, Modal },
  data() {
    return {
      lancamentos: [],
      lancamento: "",
      resumo: "",
      fields: [
        {
          key: "checkbox",
          label: "Selecionar",
        },
        {
          key: "data_lancamento",
          label: "Data Lançamento",
          sortable: true,
          formatter: (diaMesAno) => {
            return this.$moment(diaMesAno).format("DD/MM/YYYY");
          },
        },
        {
          key: "descricao",
          label: "Descrição",
          sortable: true,
          class: "text-left",
        },
        {
          key: "valor",
          label: "Valor",
          sortable: true,
          formatter: (valor) => {
            return Intl.NumberFormat("pt-BR", {
              style: "currency",
              currency: "BRL",
            }).format(valor);
          },
          class: "text-left",
        },
        { key: "actions", label: "Ações" },
      ],
    };
  },
  computed: {
    mesAno() {
      return `${this.$route.params.mes}/${this.$route.params.ano}`;
    },
  },
  watch: {
    $route(to) {
      this.lancamentos = [];
      const { mes, ano } = to.params;
      this.getLancamentosMes(mes, ano);
    },
  },
  methods: {
    modalDelete(item) {
      this.$bvModal.show("delete-release");
      this.lancamento = { ...item, valor: currencyMask(item.valor) };
    },
    excluirLancamento(id) {
      this.$bvModal.hide('delete-release');
      this.$store.commit("setLoading", true);
      this.$api
        .delete(`${process.env.VUE_APP_LANCAMENTOS_V1}/${id}`)
        .then(() => {
          this.$toasted.global.defaultSuccess();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((e) => {
          showError(e);
          this.$store.commit("setLoading", false);
        });
    },
    getLancamentosMes(mes, ano) {
      this.$store.commit("setLoading", true);
      this.$api
        .get(`${process.env.VUE_APP_LANCAMENTOS_V1}/${mes}/mes/${ano}/ano`)
        .then((res) => {
          this.lancamentos = res.data;
          this.$store.commit("setLoading", false);
        });
    },
    pagarLancamentos(lancamentos, data_pgto) {
      this.$store.commit("setLoading", true);
      let i = 0;
      lancamentos.map((lancamento) => {
        this.$api
          .put(`${process.env.VUE_APP_LANCAMENTOS_V1}/${lancamento.id}`, {
            ...lancamento,
            pago: 1,
            data_deposito_pagamento: data_pgto,
          })
          .then(() => {
            i++;
            if (i == lancamentos.length) {
              this.$toasted.global.defaultSuccess();
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            }
          })
          .catch((e) => {
            showError(e);
            this.$store.commit("setLoading", false);
          });
      });
    },
  },
  async mounted() {
    this.getLancamentosMes(this.$route.params.mes, this.$route.params.ano);
  },
};
</script>

<style>
</style>