<template>
  <div>
    <ContentHeader
      icon="fa fa-calendar"
      title="Summary of releases annual"
      subtitle="Summary of releases in the year"
    />
    <FormGroup label="Ano" label_for="dashboard-ano">
      <b-form-select
        class="w-25"
        id="dashboard-ano"
        :options="anos"
        v-model="ano"
      />
    </FormGroup>
    <b-card>
      <b-card-title
        ><i class="fas fa-calendar-alt mr-2"></i
        ><strong>Annual Control</strong></b-card-title
      >
      <b-card-body>
        <b-table-simple
          striped
          hover
          class="text-center"
          small
          bordered
          responsive
        >
          <b-thead head-variant="dark">
            <b-tr>
              <b-th
                v-for="(head, key) in resumoAno.fields"
                :class="head.class"
                :key="key"
                >{{ head.title }}</b-th
              >
              <b-th>Visualiar</b-th>
            </b-tr>
          </b-thead>
          <b-tbody style="cursor: pointer">
            <b-tr v-for="(field, key) in resumoAno.data" :key="key">
              <b-td
                v-for="(data, keyData) in resumoAno.fields"
                :key="keyData"
                :class="field[data.key].class"
                >{{ field[data.key].value }}</b-td
              >
              <b-td
                ><a @click.prevent="lancamentosMes(field.mes_num)"
                  ><i class="fas fa-chart-pie mr-2"></i></a
              >
              <a @click.prevent="lancamentosMesDetalhes(field.mes_num)"
                  ><i class="fas fa-search"></i></a
              >
            </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import ContentHeader from "../../common/templates/ContentHeader";
import Box from "../../common/widgets/Box";
import Row from "../../common/layouts/Row";
import FormGroup from "../../common/form/FormGroup";
import { showError, currencyMask } from "@/functions";

export default {
  name: "LancamentoAno",
  components: { ContentHeader, Box, Row, FormGroup },
  data() {
    return {
      ano: this.$moment().format("YYYY"),
      anos: [],
      resumoAno: []
    };
  },
  watch: {
    ano(ano) {
      this.getResumoAno(ano);
    },
  },
  methods: {
    async getResumoAno(ano) {
      this.$store.commit("setLoading", true);
      return await this.$api
        .get(`${process.env.VUE_APP_LANCAMENTOS_V1}/${ano}/ano/resumo`)
        .then((res) => {
          this.resumoAno = {
            fields: [
              { title: "Mês", key: "mes" },
              {
                title: "Receitas",
                key: "receita",
                class: "d-none d-sm-table-cell",
              },
              {
                title: "Gastos",
                key: "gasto",
                class: "d-none d-sm-table-cell",
              },
              { title: "Receitas (-) Gastos", key: "saldo" },
            ],
            data: res.data.map(({ mes, mes_num, receita, gasto, saldo }) => ({
              mes: { value: mes, class: "table-secondary font-weight-bold" },
              mes_num,
              receita: {
                value: currencyMask(receita),
                class: "d-none d-sm-table-cell",
              },
              gasto: {
                value: currencyMask(gasto),
                class: "d-none d-sm-table-cell",
              },
              saldo: {
                value: currencyMask(saldo),
                class: `table-${saldo > 0 ? "success" : "danger"}`,
              },
            })),
          };
          this.$store.commit("setLoading", false);
        })
        .catch((e) => {
          showError(e);
          this.$store.commit("setLoading", false);
        });
    },
    async getAnos() {
      await this.$api
        .get(`${process.env.VUE_APP_LANCAMENTOS_V1}/anos`)
        .then(({ data }) => (this.anos = data));
    },
    lancamentosMes(mes) {
      this.$router.push({
        name: "lancamento.mes.resumo",
        params: { mes, ano: this.ano },
      });
    },
    lancamentosMesDetalhes(mes) {
      this.$router.push({
        name: "lancamento.mes.detalhes",
        params: { mes, ano: this.ano },
      });
    },
  },
  mounted() {
    this.getAnos();
    this.getResumoAno(this.ano);
  },
};
</script>

<style>
</style>