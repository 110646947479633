<template>
  <footer class="main-footer">
    <strong
      >Copyright © 1992-2023
      <a href="https://github.com/xaamax" target="_blank">GitHub</a>.</strong
    >
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block"><b>Version</b> 1.0.0</div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>