<template>
  <div class="card card-primary card-outline card-outline-tabs">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tabs",
};
</script>

<style>
</style>